export const RESET_STREAM_STATE = "RESET_STREAM_STATE";

export const START_CREATE_NEW_STREAM = "START_CREATE_NEW_STREAM";
export const SUCCESS_CREATE_NEW_STREAM = "SUCCESS_CREATE_NEW_STREAM";
export const ERROR_CREATE_NEW_STREAM = "ERROR_CREATE_NEW_STREAM";

export const START_STREAM_STOP = "START_STREAM_STOP";
export const SUCCESS_STREAM_STOP = "SUCCESS_STREAM_STOP";
export const ERROR_STREAM_STOP = "ERROR_STREAM_STOP";

export const START_GET_STREAM_BY_ID = "START_GET_STREAM_BY_ID";
export const SUCCESS_GET_STREAM_BY_ID = "SUCCESS_GET_STREAM_BY_ID";
export const ERROR_GET_STREAM_BY_ID = "ERROR_GET_STREAM_BY_ID";

export const START_JOIN_LIVE_STREAM = "START_JOIN_LIVE_STREAM";
export const SUCCESS_JOIN_LIVE_STREAM = "SUCCESS_JOIN_LIVE_STREAM";
export const ERROR_JOIN_LIVE_STREAM = "ERROR_JOIN_LIVE_STREAM";

export const START_LEAVE_LIVE_STREAM = "START_LEAVE_LIVE_STREAM";
export const SUCCESS_LEAVE_LIVE_STREAM = "SUCCESS_LEAVE_LIVE_STREAM";
export const ERROR_LEAVE_LIVE_STREAM = "ERROR_LEAVE_LIVE_STREAM";

export const START_SHARE_STREAM_AS_POST = "START_SHARE_STREAM_AS_POST";
export const SUCCESS_SHARE_STREAM_AS_POST = "SUCCESS_SHARE_STREAM_AS_POST";
export const ERROR_SHARE_STREAM_AS_POST = "ERROR_SHARE_STREAM_AS_POST";

export const START_PURCHASE_STREAM = "START_PURCHASE_STREAM";
export const SUCCESS_PURCHASE_STREAM = "SUCCESS_PURCHASE_STREAM";
export const ERROR_PURCHASE_STREAM = "ERROR_PURCHASE_STREAM";

export const START_UPDATE_TIP_GOAL = "START_UPDATE_TIP_GOAL";
export const SUCCESS_UPDATE_TIP_GOAL = "SUCCESS_UPDATE_TIP_GOAL";
export const ERROR_UPDATE_TIP_GOAL = "ERROR_UPDATE_TIP_GOAL";

// Socket
export const SOCKET_END_STREAM = "SOCKET_END_STREAM";
export const SOCKET_USER_LEAVE_STREAM = "SOCKET_USER_LEAVE_STREAM";
export const SOCKET_USER_JOIN_STREAM = "SOCKET_USER_JOIN_STREAM";
export const SOCKET_TIP_ADD_LIVE_STREAM = "SOCKET_TIP_ADD_LIVE_STREAM";
export const SOCKET_UPDATE_TIP_GOAL = "SOCKET_UPDATE_TIP_GOAL";
