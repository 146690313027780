import axios from "axios";
import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  CREATE_POST_ENDPOINT,
  CREATE_POST_SERVER_ENDPOINT,
  GET_ALL_POST_ENDPOINT,
  DELETE_POST_ENDPOINT,
  UPDATE_POST_ENDPOINT,
  GET_OTHER_USER_POSTS_ENDPOINT,
  LIKE_POST_ENDPOINT,
  GET_POST_COMMENTS_ENDPOINT,
  CREATE_POST_COMMENT_ENDPOINT,
  UPDATE_COMMENT_ENDPOINT,
  DELETE_COMMENT_ENDPOINT,
  GET_COMMENT_REPLIES_ENDPOINT,
  GET_POSTS_BY_ID,
  ADD_BOOKMARK_ENDPOINT,
  REMOVE_BOOKMARK_ENDPOINT,
  GET_BOOKMARK_ENDPOINT,
  LINK_PRIVIEW_ENDPOINT,
  GET_PROFILE_MEDIA_ENDPOINT,
  UNLOCK_POST_ENDPOINT,
  CREATE_NEW_POST_ENDPOINT,
  UPDATE_POST_CREATORS_ENDPOINT,
  GET_MENTION_USERS_ENDPOINT,
  GET_BOOKMARK_MEDIA_ENDPOINT,
  HIDE_USERS_POSTS_FROM_FEED_ACTION,
  I_DONT_LIKE_POST_ACTION,
  GET_HASHTAGS_ENDPOINT,
} from "./endpoints";

export const updatePostCreatorsAPI = (payload) => {
  return API.put(UPDATE_POST_CREATORS_ENDPOINT, payload);
};

export const createPostAPI = (payload) => {
  return API.post(CREATE_POST_ENDPOINT, payload);
};

export const createNewPostAPI = (payload) => {
  return API.post(CREATE_NEW_POST_ENDPOINT, payload);
};

export const createPostServerCallAPI = (post_id, payload) => {
  return API.post(
    generateDynamicEndpoint(CREATE_POST_SERVER_ENDPOINT, { post_id }),
    payload
  );
};

export const getAllPostAPI = (payload) => {
  return API.get(GET_ALL_POST_ENDPOINT, payload);
};

export const uploadPostMediaOnAwsAPI = async (url, data, progressCallback) => {
  let fileCount = 0;
  // let res = data?.map(
  //   async (file, i) =>
  //     await fetch(url[i], {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       body: file,
  //     }).then((res) => (fileCount += 1))
  // );
  let res = data?.map(
    async (file, i) =>
      await axios
        .put(url[i], file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            progressCallback(data);
          },
        })
        .then((res) => (fileCount += 1))
  );
  return Promise.all(res)?.then((res) => fileCount === data?.length);
};

export const deletePostAPI = (post_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_POST_ENDPOINT, { post_id })
  );
};

export const getPostsByID = (payload) => {
  return API.post(GET_POSTS_BY_ID, payload);
};

export const updatePostAPI = (post_id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_POST_ENDPOINT, { post_id }),
    payload
  );
};

export const getOtherUserPostsAPI = (user_id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_OTHER_USER_POSTS_ENDPOINT, { user_id }),
    payload
  );
};

export const likePostAPI = (post_id) => {
  return API.post(generateDynamicEndpoint(LIKE_POST_ENDPOINT, { post_id }));
};

export const dislikePostAPI = (post_id) => {
  return API.deleteM(generateDynamicEndpoint(LIKE_POST_ENDPOINT, { post_id }));
};

export const getCommentsAPI = (post_id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_POST_COMMENTS_ENDPOINT, { post_id }),
    payload
  );
};

export const createPostCommentAPI = (post_id, payload) => {
  return API.post(
    generateDynamicEndpoint(CREATE_POST_COMMENT_ENDPOINT, { post_id }),
    payload
  );
};

export const updateCommentAPI = (post_id, comment_id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_COMMENT_ENDPOINT, { post_id, comment_id }),
    payload
  );
};

export const deleteCommentAPI = (post_id, comment_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_COMMENT_ENDPOINT, { post_id, comment_id })
  );
};

export const getCommentRepliesAPI = (post_id, comment_id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_COMMENT_REPLIES_ENDPOINT, {
      post_id,
      comment_id,
    }),
    payload
  );
};

export const addBookmarkAPI = (payload) => {
  return API.post(ADD_BOOKMARK_ENDPOINT, payload);
};

export const removeBookmarkAPI = (payload) => {
  return API.post(REMOVE_BOOKMARK_ENDPOINT, payload);
};

export const getBookmarkAPI = (payload) => {
  return API.post(GET_BOOKMARK_ENDPOINT, payload);
};

export const getBookmarkMediaAPI = (payload) => {
  return API.post(GET_BOOKMARK_MEDIA_ENDPOINT, payload);
};

export const getLinkPriviewAPI = (payload) => {
  return API.post(LINK_PRIVIEW_ENDPOINT, payload);
};

export const getProfileMediaAPI = (payload) => {
  return API.post(GET_PROFILE_MEDIA_ENDPOINT, payload);
};

export const unlockPostAPI = (payload) => {
  return API.post(UNLOCK_POST_ENDPOINT, payload);
};

export const getMentionUsersAPI = (payload) => {
  return API.get(GET_MENTION_USERS_ENDPOINT, payload);
};

export const hideUserPostsFromFeedAPI = (hide_user_id) => {
  return API.post(
    generateDynamicEndpoint(HIDE_USERS_POSTS_FROM_FEED_ACTION, { hide_user_id })
  );
};

export const iDontLikePostAPI = (id) => {
  return API.post(generateDynamicEndpoint(I_DONT_LIKE_POST_ACTION, { id }));
};

export const getHashTagsAPI = (payload) => {
  return API.get(GET_HASHTAGS_ENDPOINT, payload);
};