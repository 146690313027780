import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  DISMISS_USER_HINTS_ENDPOINT,
  GET_USER_HINTS_ENDPOINT,
  POST_GENERATE_AWS_PUT_OBJECT_SIGNED_URL,
} from "./endpoints";

export const generateAwsPutObjectSignedUrl = (payload) => {
  return API.post(POST_GENERATE_AWS_PUT_OBJECT_SIGNED_URL, payload);
};

export const getUserHintsAPI = (queryParams) => {
  return API.get(GET_USER_HINTS_ENDPOINT, queryParams);
};

export const dismissUserHintsAPI = (queryParams) => {
  return API.deleteM(
    generateDynamicEndpoint(DISMISS_USER_HINTS_ENDPOINT, queryParams)
  );
};
