import {
  RESET_VAULT_ACTION,
  SET_VAULT_CONFIG_DATA,
  START_GET_VAULT_LIST,
  SUCCESS_GET_VAULT_LIST,
  ERROR_GET_VAULT_LIST,
  START_CREATE_VAULT_LIST,
  SUCCESS_CREATE_VAULT_LIST,
  ERROR_CREATE_VAULT_LIST,
  START_UPDATE_VAULT_LIST,
  SUCCESS_UPDATE_VAULT_LIST,
  ERROR_UPDATE_VAULT_LIST,
  START_DELETE_VAULT_LIST,
  SUCCESS_DELETE_VAULT_LIST,
  ERROR_DELETE_VAULT_LIST,
  START_GET_SINGLE_VAULT_LIST,
  SUCCESS_GET_SINGLE_VAULT_LIST,
  ERROR_GET_SINGLE_VAULT_LIST,
  START_CHANGE_VAULT_LIST_ORDER,
  SUCCESS_CHANGE_VAULT_LIST_ORDER,
  ERROR_CHANGE_VAULT_LIST_ORDER,
  START_GET_INNER_VAULT,
  SUCCESS_GET_INNER_VAULT,
  ERROR_GET_INNER_VAULT,
  START_GET_VAULT_MEDIA_LIST,
  SUCCESS_GET_VAULT_MEDIA_LIST,
  ERROR_GET_VAULT_MEDIA_LIST,
  START_DELETE_VAULT_MEDIA,
  SUCCESS_DELETE_VAULT_MEDIA,
  ERROR_DELETE_VAULT_MEDIA,
  START_CHANGE_MEDIA_CATEGORY,
  SUCCESS_CHANGE_MEDIA_CATEGORY,
  ERROR_CHANGE_MEDIA_CATEGORY,
  START_GET_VAULT_MEDIA_BY_ID,
  SUCCESS_GET_VAULT_MEDIA_BY_ID,
  ERROR_GET_VAULT_MEDIA_BY_ID,
} from "Actions/VaultAction";
import { setValueFromPath } from "utils/generalUtils";

const initialState = {
  vaultConfig: {
    vaultDialog: {
      open: false,
    },
    vaultMedia: {
      open: false,
      ids: [],
      confirm: false,
      use: false,
    },
    category: {
      open: false,
      editValue: "",
      confirm: false,
      listOpen: false,
    },
    selectedFilter: {
      orderBy: "index",
      sortBy: "asc",
    },
    selectedMedia: [],
    mediaToUsed: [],
  },
  vaultList: {
    isLoading: false,
    data: {},
    error: "",
  },
  innerVaultList: {
    isLoading: false,
    data: {},
    error: "F",
  },
  createVaultData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateVaultData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteVaultData: {
    isLoading: false,
    data: {},
    error: "",
    remove_media: false,
  },
  singleVaultData: {
    isLoading: false,
    data: {},
    error: "",
  },
  changeListOrderData: {
    isLoading: false,
    data: {},
    error: "",
  },
  vaultMediaList: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteVaultMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  changeMediaCategryData: {
    isLoading: false,
    data: {},
    error: "",
  },
  vaultMediaByIds: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const vaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_VAULT_ACTION: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action?.key],
      };
    }
    case SET_VAULT_CONFIG_DATA: {
      return {
        ...state,
        vaultConfig: {
          ...state?.vaultConfig,
          ...action?.payload,
        },
      };
    }

    case START_GET_VAULT_LIST: {
      let oldList = { ...state?.vaultList?.data };
      return {
        ...state,
        vaultList: {
          isLoading: action?.payload?.lazyLoad ? false : true,
          data: action?.payload?.lazyLoad ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_VAULT_LIST: {
      return {
        ...state,
        vaultList: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_VAULT_LIST: {
      return {
        ...state,
        vaultList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_INNER_VAULT: {
      let oldList = { ...state?.innerVaultList?.data };

      return {
        ...state,
        innerVaultList: {
          isLoading: action?.payload.lazyLoad ? false : true,
          data: action?.payload.lazyLoad ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_INNER_VAULT: {
      return {
        ...state,
        innerVaultList: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_INNER_VAULT: {
      return {
        ...state,
        innerVaultList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CREATE_VAULT_LIST: {
      return {
        ...state,
        createVaultData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_VAULT_LIST: {
      let oldList = { ...state?.vaultList?.data };
      let oldInnerList = { ...state?.innerVaultList?.data };
      if (oldList?.list?.rows?.length) {
        oldList["list"]["rows"] = [
          ...oldList?.list?.rows,
          action.payload?.data,
        ];
      }
      setValueFromPath(oldInnerList, "list.rows", [
        ...(oldInnerList?.list?.rows || []),
        action.payload?.data,
      ]);
      return {
        ...state,
        createVaultData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        vaultList: {
          ...state?.vaultList,
          data: oldList,
        },
        innerVaultList: {
          ...state?.innerVaultList,
          data: oldInnerList,
        },
      };
    }
    case ERROR_CREATE_VAULT_LIST: {
      return {
        ...state,
        createVaultData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_VAULT_LIST: {
      return {
        ...state,
        updateVaultData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_VAULT_LIST: {
      let oldList = { ...state?.vaultList?.data };
      let oldData = { ...state?.singleVaultData?.data };
      if (oldList?.list?.rows?.length) {
        oldList["list"]["rows"] = oldList?.list?.rows?.map((item) => {
          if (item?.id == action?.payload?.data?.id) {
            return {
              ...item,
              ...action?.payload?.data,
            };
          } else {
            return item;
          }
        });
      }
      if (Object.keys(oldData || {})?.length) {
        oldData["data"] = { ...oldData?.data, ...action?.payload?.data };
      }
      return {
        ...state,
        updateVaultData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        vaultList: {
          ...state?.vaultList,
          data: oldList,
        },
        singleVaultData: {
          ...state?.singleVaultData,
          data: oldData,
        },
      };
    }
    case ERROR_UPDATE_VAULT_LIST: {
      return {
        ...state,
        updateVaultData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DELETE_VAULT_LIST: {
      return {
        ...state,
        deleteVaultData: {
          isLoading: true,
          data: {},
          error: "",
          remove_media: false,
        },
      };
    }
    case SUCCESS_DELETE_VAULT_LIST: {
      let oldList = { ...state?.vaultList?.data };
      if (oldList?.list?.rows?.length) {
        oldList["list"]["rows"] = oldList?.list?.rows?.filter(
          (item) => item?.id != action?.vaultId
        );
      }
      return {
        ...state,
        deleteVaultData: {
          isLoading: false,
          data: action.payload,
          error: "",
          remove_media: action?.remove_media,
        },
        vaultList: {
          ...state?.vaultList,
          data: oldList,
        },
      };
    }
    case ERROR_DELETE_VAULT_LIST: {
      return {
        ...state,
        deleteVaultData: {
          isLoading: false,
          data: {},
          error: action.payload,
          remove_media: false,
        },
      };
    }

    case START_GET_SINGLE_VAULT_LIST: {
      return {
        ...state,
        singleVaultData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_SINGLE_VAULT_LIST: {
      return {
        ...state,
        singleVaultData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_SINGLE_VAULT_LIST: {
      return {
        ...state,
        singleVaultData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CHANGE_VAULT_LIST_ORDER: {
      return {
        ...state,
        changeListOrderData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHANGE_VAULT_LIST_ORDER: {
      return {
        ...state,
        changeListOrderData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CHANGE_VAULT_LIST_ORDER: {
      return {
        ...state,
        changeListOrderData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_VAULT_MEDIA_LIST: {
      let oldList = { ...state?.vaultMediaList?.data };

      return {
        ...state,
        vaultMediaList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_VAULT_MEDIA_LIST: {
      let oldList = { ...state?.vaultMediaList?.data };

      if (oldList?.list?.length > 0) {
        oldList["list"] = [...oldList?.list, ...action?.payload?.list];
      } else {
        oldList = action.payload;
      }

      return {
        ...state,
        vaultMediaList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_VAULT_MEDIA_LIST: {
      return {
        ...state,
        vaultMediaList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DELETE_VAULT_MEDIA: {
      return {
        ...state,
        deleteVaultMediaData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_VAULT_MEDIA: {
      let oldList = { ...state?.vaultMediaList?.data };

      if (action?.media_ids?.length) {
        let includeListMediaCount = 0;
        oldList?.list?.map((item) =>
          action?.media_ids?.includes(item?.vault_media?.id)
            ? includeListMediaCount++
            : includeListMediaCount
        );
        oldList.list = oldList?.list?.filter(
          (item) => !action?.media_ids?.includes(item?.vault_media?.id)
        );
        oldList.count = oldList?.count - includeListMediaCount;
      }

      return {
        ...state,
        deleteVaultMediaData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        vaultMediaList: {
          ...state?.vaultMediaList,
          data: oldList,
        },
      };
    }
    case ERROR_DELETE_VAULT_MEDIA: {
      return {
        ...state,
        deleteVaultMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CHANGE_MEDIA_CATEGORY: {
      return {
        ...state,
        changeMediaCategryData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHANGE_MEDIA_CATEGORY: {
      let oldConfig = { ...state?.vaultConfig };
      let oldMediaList = { ...state?.vaultMediaList?.data };

      oldConfig.selectedMedia = oldConfig?.selectedMedia?.map((item) => {
        if (action?.data?.media_ids?.includes(item?.id)) {
          let ids = [...item?.vault_ids];
          if (action?.data?.type == "add") {
            ids = [...ids, action?.data?.id];
          } else {
            ids = ids?.filter((id) => id != action?.data?.id);
          }
          return {
            ...item,
            vault_ids: ids,
          };
        } else {
          return item;
        }
      });

      if (oldMediaList?.list?.length > 0) {
        oldMediaList.list = oldMediaList?.list?.map((item) => {
          if (action?.data?.media_ids?.includes(item?.vault_media?.id)) {
            if (action?.data?.type == "add") {
              return {
                ...item,
                vault_media: {
                  ...item?.vault_media,
                  vault_media_media_list: [
                    ...(item?.vault_media?.vault_media_media_list || []),
                    { vault_id: action?.data?.id },
                  ],
                },
                hide: false,
              };
            } else {
              return {
                ...item,
                vault_media: {
                  ...item?.vault_media,
                  vault_media_media_list:
                    item?.vault_media?.vault_media_media_list?.filter(
                      (media) => media?.vault_id != action?.data?.id
                    ),
                },
                hide: true,
              };
            }
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        changeMediaCategryData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        vaultConfig: oldConfig,
        vaultMediaList: {
          ...state?.vaultMediaList,
          data: oldMediaList,
        },
      };
    }
    case ERROR_CHANGE_MEDIA_CATEGORY: {
      return {
        ...state,
        changeMediaCategryData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_VAULT_MEDIA_BY_ID: {
      return {
        ...state,
        vaultMediaByIds: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_VAULT_MEDIA_BY_ID: {
      return {
        ...state,
        vaultMediaByIds: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_VAULT_MEDIA_BY_ID: {
      return {
        ...state,
        vaultMediaByIds: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    default: {
      return { ...(state || initialState) };
    }
  }
};
