import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_CLICK_TRACK_LINK,
  ERROR_CLOSE_LOGIN_SESSIONS,
  ERROR_CREATE_CHAT_MSG,
  ERROR_CREATE_TRACKING_LINK,
  ERROR_DELETE_TRACKING_LINK,
  ERROR_DELETE_WELCOME_MSG,
  ERROR_DISCONNECT_PHONE_NUMBER,
  ERROR_GET_AUTH_CODES,
  ERROR_GET_LOGIN_SESSIONS,
  ERROR_GET_TRACKING_LINKS,
  ERROR_GET_TRACKING_LINK_SUBSCRIBERS,
  ERROR_GET_USER_METADATA,
  ERROR_GET_WELCOME_MSG,
  ERROR_SEND_OTP_CODE,
  ERROR_SEND_SECURITY_CODE,
  ERROR_TOGGLE_TWO_STEP_AUTH,
  ERROR_UPDATE_CHAT_SETTINGS,
  ERROR_UPDATE_USER_METADATA,
  ERROR_UPDATE_WELCOME_MSG,
  ERROR_VERIFY_SECURITY_CODE,
  START_CLICK_TRACK_LINK,
  START_CLOSE_LOGIN_SESSIONS,
  START_CREATE_CHAT_MSG,
  START_CREATE_TRACKING_LINK,
  START_DELETE_TRACKING_LINK,
  START_DELETE_WELCOME_MSG,
  START_DISCONNECT_PHONE_NUMBER,
  START_GET_AUTH_CODES,
  START_GET_LOGIN_SESSIONS,
  START_GET_TRACKING_LINKS,
  START_GET_TRACKING_LINK_SUBSCRIBERS,
  START_GET_USER_METADATA,
  START_GET_WELCOME_MSG,
  START_SEND_OTP_CODE,
  START_SEND_SECURITY_CODE,
  START_TOGGLE_TWO_STEP_AUTH,
  START_UPDATE_CHAT_SETTINGS,
  START_UPDATE_USER_METADATA,
  START_UPDATE_WELCOME_MSG,
  START_VERIFY_SECURITY_CODE,
  SUCCESS_CLICK_TRACK_LINK,
  SUCCESS_CLOSE_LOGIN_SESSIONS,
  SUCCESS_CREATE_CHAT_MSG,
  SUCCESS_CREATE_TRACKING_LINK,
  SUCCESS_DELETE_TRACKING_LINK,
  SUCCESS_DELETE_WELCOME_MSG,
  SUCCESS_DISCONNECT_PHONE_NUMBER,
  SUCCESS_GET_AUTH_CODES,
  SUCCESS_GET_LOGIN_SESSIONS,
  SUCCESS_GET_TRACKING_LINKS,
  SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS,
  SUCCESS_GET_USER_METADATA,
  SUCCESS_GET_WELCOME_MSG,
  SUCCESS_SEND_OTP_CODE,
  SUCCESS_SEND_SECURITY_CODE,
  SUCCESS_TOGGLE_TWO_STEP_AUTH,
  SUCCESS_UPDATE_CHAT_SETTINGS,
  SUCCESS_UPDATE_USER_METADATA,
  SUCCESS_UPDATE_WELCOME_MSG,
  SUCCESS_VERIFY_SECURITY_CODE,
} from "Actions/SettingsAction";
import {
  clickTrackLinkAPI,
  closeLoginSessionAPI,
  createTrackingLinkAPI,
  createWelcomeMsgAPI,
  deleteTrackingLinkAPI,
  deleteWelcomeMsgAPI,
  disconnectPhoneAPI,
  getAuthCodesAPI,
  getLoginSessionAPI,
  getTrackingLinkSubscribersAPI,
  getTrackingLinksAPI,
  getUserMetadataAPI,
  getWelcomeMsgAPI,
  sendOtpCodeAPI,
  sendSecurityCodeAPI,
  toggleTwoStepAuthAPI,
  updateChatSettingAPI,
  updateUserMetadataAPI,
  updateWelcomeMsgAPI,
  verifySecurityCodeAPI,
} from "Api/SettingsApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* updateChatSettingWorker({ payload }) {
  try {
    const res = yield call(updateChatSettingAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_CHAT_SETTINGS,
        payload: res,
        data: payload,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_CHAT_SETTINGS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteWelcomeMsgWorker({ id }) {
  try {
    const res = yield call(deleteWelcomeMsgAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_WELCOME_MSG,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_WELCOME_MSG,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong, Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getWelcomeMsgWorker() {
  try {
    const res = yield call(getWelcomeMsgAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_WELCOME_MSG,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_WELCOME_MSG,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createWelcomeMsgWorker({ payload }) {
  try {
    const res = yield call(createWelcomeMsgAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_CHAT_MSG,
        payload: res,
        paymentDetail: payload?.tip_payment_info,
        is_scheduled: payload?.is_scheduled,
      });
      if (payload?.type == "welcome_message") {
        yield put({
          type: ADD_NEW_NOTIFICATION,
          payload: {
            message: "Changes were saved successfully.",
            type: "success",
          },
        });
      }
    } else {
      yield put({
        type: ERROR_CREATE_CHAT_MSG,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong, Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateWelcomeMsgWorker({ payload }) {
  try {
    const res = yield call(updateWelcomeMsgAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_WELCOME_MSG,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_WELCOME_MSG,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong, Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getTrackingLinksWorker({ payload }) {
  try {
    const res = yield call(getTrackingLinksAPI, payload);
    if (res?.status) {
      yield put({
        type: SUCCESS_GET_TRACKING_LINKS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_TRACKING_LINKS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong! Please try again",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* createTrackingLinkWorker({ payload }) {
  try {
    const res = yield call(createTrackingLinkAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_TRACKING_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_TRACKING_LINK,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteTrackingLinkWorker({ id }) {
  try {
    const res = yield call(deleteTrackingLinkAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_TRACKING_LINK,
        payload: res,
        link_id: id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_TRACKING_LINK,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* clickTrackLinkWorker({ payload }) {
  try {
    const res = yield call(clickTrackLinkAPI, payload);
    if (res?.status) {
      yield put({
        type: SUCCESS_CLICK_TRACK_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CLICK_TRACK_LINK,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getTrackingLinkSubscribersWorker({ id, payload }) {
  try {
    const res = yield call(getTrackingLinkSubscribersAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS,
        payload: res,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_TRACKING_LINK_SUBSCRIBERS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* sendSecurityCodeWorker({ payload }) {
  try {
    const res = yield call(sendSecurityCodeAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SEND_SECURITY_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: res?.message || "Security code sent successfully.",
        },
      });
    } else {
      yield put({
        type: ERROR_SEND_SECURITY_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message:
            res?.message || "Unable to send code! Please try after sometime.",
        },
      });
    }
  } catch (error) {}
}
function* sendOtpCodeWorker({ payload }) {
  try {
    const res = yield call(sendOtpCodeAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SEND_OTP_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: res?.message || "Security code sent successfully.",
        },
      });
    } else {
      yield put({
        type: ERROR_SEND_OTP_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message: "Unable to send code! Please try after sometime.",
        },
      });
    }
  } catch (error) {}
}

function* verifySecurityCodeWorker({ payload }) {
  try {
    const res = yield call(verifySecurityCodeAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_VERIFY_SECURITY_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: res?.message || "Security code verified successfully.",
        },
      });
    } else {
      yield put({
        type: ERROR_VERIFY_SECURITY_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message:
            res?.message || "Something went wrong! Please try after sometime.F",
        },
      });
    }
  } catch (error) {}
}

function* disconnectPhoneWorker({ payload }) {
  try {
    const res = yield call(disconnectPhoneAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DISCONNECT_PHONE_NUMBER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: res?.message || "Mobile number disconnected successfully.",
        },
      });
    } else {
      yield put({
        type: ERROR_DISCONNECT_PHONE_NUMBER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message:
            res?.message || "Something went wrong! Please try again later",
        },
      });
    }
  } catch (error) {}
}

function* toggleTwoStepAuthWorker({ payload }) {
  try {
    const res = yield call(toggleTwoStepAuthAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_TOGGLE_TWO_STEP_AUTH,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: res?.message,
        },
      });
    } else {
      yield put({
        type: ERROR_TOGGLE_TWO_STEP_AUTH,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message: res?.message,
        },
      });
    }
  } catch (error) {}
}

function* getAuthCodesWorker({ payload }) {
  try {
    const res = yield call(getAuthCodesAPI, payload);

    if (res.status) {
      yield put({
        type: SUCCESS_GET_AUTH_CODES,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_AUTH_CODES,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message:
            res?.message || "Something went wrong! Please try again later",
        },
      });
    }
  } catch (error) {}
}

function* getLoginSessionWorker() {
  try {
    const res = yield call(getLoginSessionAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_LOGIN_SESSIONS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_LOGIN_SESSIONS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* closeLoginSessionWorker() {
  try {
    const res = yield call(closeLoginSessionAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_CLOSE_LOGIN_SESSIONS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CLOSE_LOGIN_SESSIONS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getUserMetadataWorker() {
  try {
    const res = yield call(getUserMetadataAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_USER_METADATA,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_USER_METADATA,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateUserMetadataWorker({ payload }) {
  try {
    const res = yield call(updateUserMetadataAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_USER_METADATA,
        payload: res,
        data: payload,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "success",
          message: "Changes were saved successfully.",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_USER_METADATA,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message: "Something went wrong! Changes were not saved.",
        },
      });
    }
  } catch (error) {}
}

export function* SettingsWatcher() {
  yield takeEvery(START_UPDATE_CHAT_SETTINGS, updateChatSettingWorker);
  yield takeEvery(START_DELETE_WELCOME_MSG, deleteWelcomeMsgWorker);
  yield takeEvery(START_GET_WELCOME_MSG, getWelcomeMsgWorker);
  yield takeEvery(START_CREATE_CHAT_MSG, createWelcomeMsgWorker);
  yield takeEvery(START_UPDATE_WELCOME_MSG, updateWelcomeMsgWorker);
  yield takeEvery(START_GET_TRACKING_LINKS, getTrackingLinksWorker);
  yield takeEvery(START_CREATE_TRACKING_LINK, createTrackingLinkWorker);
  yield takeEvery(START_DELETE_TRACKING_LINK, deleteTrackingLinkWorker);
  yield takeEvery(START_CLICK_TRACK_LINK, clickTrackLinkWorker);
  yield takeEvery(
    START_GET_TRACKING_LINK_SUBSCRIBERS,
    getTrackingLinkSubscribersWorker
  );
  yield takeEvery(START_SEND_SECURITY_CODE, sendSecurityCodeWorker);
  yield takeEvery(START_SEND_OTP_CODE, sendOtpCodeWorker);
  yield takeEvery(START_VERIFY_SECURITY_CODE, verifySecurityCodeWorker);
  yield takeEvery(START_DISCONNECT_PHONE_NUMBER, disconnectPhoneWorker);
  yield takeEvery(START_TOGGLE_TWO_STEP_AUTH, toggleTwoStepAuthWorker);
  yield takeEvery(START_GET_AUTH_CODES, getAuthCodesWorker);
  yield takeEvery(START_GET_LOGIN_SESSIONS, getLoginSessionWorker);
  yield takeEvery(START_CLOSE_LOGIN_SESSIONS, closeLoginSessionWorker);
  yield takeEvery(START_GET_USER_METADATA, getUserMetadataWorker);
  yield takeEvery(START_UPDATE_USER_METADATA, updateUserMetadataWorker);
}
