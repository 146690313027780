import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  ADD_PROMOTION_CAMPAIGN_ENDPOINT,
  ADD_SUBSCRIPTION_BUNDLE_ENDPOINT,
  CHECK_FREE_TRIAL_AVAILABILITY_ENDPOINT,
  CREATE_FREE_TRIAL_LINKS_ENDPOINT,
  DELETE_FREE_TRIAL_LINKS_ENDPOINT,
  DELETE_PROMOTION_CAMPAIGN_ENDPOINT,
  DELETE_SUBSCIPTION_BUNDLE_ENDPOINT,
  GET_FREE_TRIAL_LINKS_ENDPOINT,
  JOIN_FREE_TRIAL_ENDPOINT,
  SET_SUBSCRIPTION_PRICE_ENDPOINT,
  STOP_PROMOTION_CAMPAIGN_ENDPOINT,
  SUBSCRIPTION_ACTION_ENDPOINT,
} from "./endpoints";

export const setSubscriptionPriceAPI = (payload) => {
  return API.post(SET_SUBSCRIPTION_PRICE_ENDPOINT, payload);
};

export const getFreeTrialLinksAPI = (payload) => {
  return API.get(GET_FREE_TRIAL_LINKS_ENDPOINT, payload);
};

export const createFreeTrialLinkAPI = (payload) => {
  return API.post(CREATE_FREE_TRIAL_LINKS_ENDPOINT, payload);
};

export const deleteFreeTrialLinkAPI = (link_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_FREE_TRIAL_LINKS_ENDPOINT, { link_id })
  );
};

export const checkFreeTrialAvailabilityAPI = (payload) => {
  return API.post(CHECK_FREE_TRIAL_AVAILABILITY_ENDPOINT, payload);
};

export const joinFreeTrialAPI = (payload) => {
  return API.post(JOIN_FREE_TRIAL_ENDPOINT, payload);
};

export const addSubscriptionBundleAPI = (payload) => {
  return API.post(ADD_SUBSCRIPTION_BUNDLE_ENDPOINT, payload);
};

export const deleteSubscriptionBundleAPI = (bundle_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_SUBSCIPTION_BUNDLE_ENDPOINT, { bundle_id })
  );
};

export const addPromotionCampaignAPI = (payload) => {
  return API.post(ADD_PROMOTION_CAMPAIGN_ENDPOINT, payload);
};

export const deletePromotionCampaignAPI = (campaign_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_PROMOTION_CAMPAIGN_ENDPOINT, { campaign_id })
  );
};

export const stopPromotionCampaignAPI = (payload) => {
  return API.post(STOP_PROMOTION_CAMPAIGN_ENDPOINT, payload);
};

export const subscriptionActionAPI = (user_id, payload) => {
  return API.post(
    generateDynamicEndpoint(SUBSCRIPTION_ACTION_ENDPOINT, { user_id }),
    payload
  );
};
