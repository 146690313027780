export const CHANGE_THEME_MODE = "CHANGE_THEME_MODE";
export const RESET_USER_STATE = "RESET_USER_STATE";

export const START_REGISTER_USER = "START_REGISTER_USER";
export const SUCCESS_REGISTER_USER = "SUCCESS_REGISTER_USER";
export const ERROR_REGISTER_USER = "ERROR_REGISTER_USER";

export const START_LOGIN_USER = "START_LOGIN_USER";
export const SUCCESS_LOGIN_USER = "SUCCESS_LOGIN_USER";
export const ERROR_LOGIN_USER = "ERROR_LOGIN_USER";

export const START_VERIFY_OTP = "START_VERIFY_OTP";
export const SUCCESS_VERIFY_OTP = "SUCCESS_VERIFY_OTP";
export const ERROR_VERIFY_OTP = "ERROR_VERIFY_OTP";

export const START_GET_USER = "START_GET_USER";
export const SUCCESS_GET_USER = "SUCCESS_GET_USER";
export const ERROR_GET_USER = "ERROR_GET_USER";

export const START_FORGOT_PASSWORD = "START_FORGOT_PASSWORD";
export const SUCCESS_FORGOT_PASSWORD = "SUCCESS_FORGOT_PASSWORD";
export const ERROR_FORGOT_PASSWORD = "ERROR_FORGOT_PASSWORD";

export const START_RESET_PASSWORD = "START_RESET_PASSWORD";
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD";
export const ERROR_RESET_PASSWORD = "ERROR_RESET_PASSWORD";

export const START_UPDATE_USER_PROFILE_IMAGE =
  "START_UPDATE_USER_PROFILE_IMAGE";
export const SUCCESS_UPDATE_USER_PROFILE_IMAGE =
  "SUCCESS_UPDATE_USER_PROFILE_IMAGE";
export const ERROR_UPDATE_USER_PROFILE_IMAGE =
  "ERROR_UPDATE_USER_PROFILE_IMAGE";

export const START_UPDATE_COVER_IMAGE = "START_UPDATE_COVER_IMAGE";
export const SUCCESS_UPDATE_COVER_IMAGE = "SUCCESS_UPDATE_COVER_IMAGE";
export const ERROR_UPDATE_COVER_IMAGE = "ERROR_UPDATE_COVER_IMAGE";

export const START_UPDATE_USER_DETAILS = "START_UPDATE_USER_DETAILS";
export const SUCCESS_UPDATE_USER_DETAILS = "SUCCESS_UPDATE_USER_DETAILS";
export const ERROR_UPDATE_USER_DETAILS = "ERROR_UPDATE_USER_DETAILS";

export const START_DELETE_PROFILE_IMAGE = "START_DELETE_PROFILE_IMAGE";
export const SUCCESS_DELETE_PROFILE_IMAGE = "SUCCESS_DELETE_PROFILE_IMAGE";
export const ERROR_DELETE_PROFILE_IMAGE = "ERROR_DELETE_PROFILE_IMAGE";

export const START_DELETE_COVER_IMAGE = "START_DELETE_COVER_IMAGE";
export const SUCCESS_DELETE_COVER_IMAGE = "SUCCESS_DELETE_COVER_IMAGE";
export const ERROR_DELETE_COVER_IMAGE = "ERROR_DELETE_COVER_IMAGE";

export const START_GET_OTHER_USER_PROFILE = "START_GET_OTHER_USER_PROFILE";
export const SUCCESS_GET_OTHER_USER_PROFILE = "SUCCESS_GET_OTHER_USER_PROFILE";
export const ERROR_GET_OTHER_USER_PROFILE = "ERROR_GET_OTHER_USER_PROFILE";

export const START_CHECK_USERNAME_EXISTS = "START_CHECK_USERNAME_EXISTS";
export const SUCCESS_CHECK_USERNAME_EXISTS = "SUCCESS_CHECK_USERNAME_EXISTS";
export const ERROR_CHECK_USERNAME_EXISTS = "ERROR_CHECK_USERNAME_EXISTS";

export const START_UPDATE_USERNAME = "START_UPDATE_USERNAME";
export const SUCCESS_UPDATE_USERNAME = "SUCCESS_UPDATE_USERNAME";
export const ERROR_UPDATE_USERNAME = "ERROR_UPDATE_USERNAME";

export const START_UPDATE_PHONE_NUMBER = "START_UPDATE_PHONE_NUMBER";
export const SUCCESS_UPDATE_PHONE_NUMBER = "SUCCESS_UPDATE_PHONE_NUMBER";
export const ERROR_UPDATE_PHONE_NUMBER = "ERROR_UPDATE_PHONE_NUMBER";

export const START_UPDATE_PASSWORD = "START_UPDATE_PASSWORD";
export const SUCCESS_UPDATE_PASSWORD = "SUCCESS_UPDATE_PASSWORD";
export const ERROR_UPDATE_PASSWORD = "ERROR_UPDATE_PASSWORD";

export const START_FORGOT_PASSWORD_CODE_SEND =
  "START_FORGOT_PASSWORD_CODE_SEND";
export const SUCCESS_FORGOT_PASSWORD_CODE_SEND =
  "SUCCESS_FORGOT_PASSWORD_CODE_SEND";
export const ERROR_FORGOT_PASSWORD_CODE_SEND =
  "ERROR_FORGOT_PASSWORD_CODE_SEND";

export const START_VERIFY_EMAIL = "START_VERIFY_EMAIL";
export const SUCCESS_VERIFY_EMAIL = "SUCCESS_VERIFY_EMAIL";
export const ERROR_VERIFY_EMAIL = "ERROR_VERIFY_EMAIL";

export const START_VERIFY_EMAIL_CODE = "START_VERIFY_EMAIL_CODE";
export const SUCCESS_VERIFY_EMAIL_CODE = "SUCCESS_VERIFY_EMAIL_CODE";
export const ERROR_VERIFY_EMAIL_CODE = "ERROR_VERIFY_EMAIL_CODE";

export const START_UPDATE_EMAIL = "START_UPDATE_EMAIL";
export const SUCCESS_UPDATE_EMAIL = "SUCCESS_UPDATE_EMAIL";
export const ERROR_UPDATE_EMAIL = "ERROR_UPDATE_EMAIL";

export const START_VERIFY_UPDATE_EMAIL_CODE = "START_VERIFY_UPDATE_EMAIL_CODE";
export const SUCCESS_VERIFY_UPDATE_EMAIL_CODE =
  "SUCCESS_VERIFY_UPDATE_EMAIL_CODE";
export const ERROR_VERIFY_UPDATE_EMAIL_CODE = "ERROR_VERIFY_UPDATE_EMAIL_CODE";

export const START_DELETE_ACCOUNT = "START_DELETE_ACCOUNT";
export const SUCCESS_DELETE_ACCOUNT = "SUCCESS_DELETE_ACCOUNT";
export const ERROR_DELETE_ACCOUNT = "ERROR_DELETE_ACCOUNT";

export const START_CHANGE_ACTIVITY_STATUS = "START_CHANGE_ACTIVITY_STATUS";
export const SUCCESS_CHANGE_ACTIVITY_STATUS = "SUCCESS_CHANGE_ACTIVITY_STATUS";
export const ERROR_CHANGE_ACTIVITY_STATUS = "ERROR_CHANGE_ACTIVITY_STATUS";

export const START_LOG_OUT_USER = "START_LOG_OUT_USER";
export const SUCCESS_LOG_OUT_USER = "SUCCESS_LOG_OUT_USER";
export const ERROR_LOG_OUT_USER = "ERROR_LOG_OUT_USER";

export const START_GET_SUGGESTIONS = "START_GET_SUGGESTIONS";
export const SUCCESS_GET_SUGGESTIONS = "SUCCESS_GET_SUGGESTIONS";
export const ERROR_GET_SUGGESTIONS = "ERROR_GET_SUGGESTIONS";

export const START_SUBSCRIBE_USER = "START_SUBSCRIBE_USER";
export const SUCCESS_SUBSCRIBE_USER = "SUCCESS_SUBSCRIBE_USER";
export const ERROR_SUBSCRIBE_USER = "ERROR_SUBSCRIBE_USER";

export const START_GET_FOLLOWINGS = "START_GET_FOLLOWINGS";
export const SUCCESS_GET_FOLLOWINGS = "SUCCESS_GET_FOLLOWINGS";
export const ERROR_GET_FOLLOWINGS = "ERROR_GET_FOLLOWINGS";

export const START_GET_FOLLOWERS = "START_GET_FOLLOWERS";
export const SUCCESS_GET_FOLLOWERS = "SUCCESS_GET_FOLLOWERS";
export const ERROR_GET_FOLLOWERS = "ERROR_GET_FOLLOWERS";

export const START_BLOCK_USER = "START_BLOCK_USER";
export const SUCCESS_BLOCK_USER = "SUCCESS_BLOCK_USER";
export const ERROR_BLOCK_USER = "ERROR_BLOCK_USER";

export const START_UNBLOCK_USER = "START_UNBLOCK_USER";
export const SUCCESS_UNBLOCK_USER = "SUCCESS_UNBLOCK_USER";
export const ERROR_UNBLOCK_USER = "ERROR_UNBLOCK_USER";

export const START_GET_BLOCKED_USER_LIST = "START_GET_BLOCKED_USER_LIST";
export const SUCCESS_GET_BLOCKED_USER_LIST = "SUCCESS_GET_BLOCKED_USER_LIST";
export const ERROR_GET_BLOCKED_USER_LIST = "ERROR_GET_BLOCKED_USER_LIST";

export const START_ADD_COUNTRY_LEGAL = "START_ADD_COUNTRY_LEGAL";
export const SUCCESS_ADD_COUNTRY_LEGAL = "SUCCESS_ADD_COUNTRY_LEGAL";
export const ERROR_ADD_COUNTRY_LEGAL = "ERROR_ADD_COUNTRY_LEGAL";

export const START_IDV_PROCESS = "START_IDV_PROCESS";
export const SUCCESS_IDV_PROCESS = "SUCCESS_IDV_PROCESS";
export const ERROR_IDV_PROCESS = "ERROR_IDV_PROCESS";

export const START_REPORT = "START_REPORT";
export const SUCCESS_REPORT = "SUCCESS_REPORT";
export const ERROR_REPORT = "ERROR_REPORT";

export const START_ADD_SOCIAL_MEDIA = "START_ADD_SOCIAL_MEDIA";
export const SUCCESS_ADD_SOCIAL_MEDIA = "SUCCESS_ADD_SOCIAL_MEDIA";
export const ERROR_ADD_SOCIAL_MEDIA = "ERROR_ADD_SOCIAL_MEDIA";

export const START_UPDATE_SOCIAL_MEDIA = "START_UPDATE_SOCIAL_MEDIA";
export const SUCCESS_UPDATE_SOCIAL_MEDIA = "SUCCESS_UPDATE_SOCIAL_MEDIA";
export const ERROR_UPDATE_SOCIAL_MEDIA = "ERROR_UPDATE_SOCIAL_MEDIA";

export const START_DELETE_SOCIAL_MEDIA = "START_DELETE_SOCIAL_MEDIA";
export const SUCCESS_DELETE_SOCIAL_MEDIA = "SUCCESS_DELETE_SOCIAL_MEDIA";
export const ERROR_DELETE_SOCIAL_MEDIA = "ERROR_DELETE_SOCIAL_MEDIA";

export const START_CONNECT_USER = "START_CONNECT_USER";
export const SUCCESS_CONNECT_USER = "SUCCESS_CONNECT_USER";
export const ERROR_CONNECT_USER = "ERROR_CONNECT_USER";

export const START_DISCONNECT_USER = "START_DISCONNECT_USER";
export const SUCCESS_DISCONNECT_USER = "SUCCESS_DISCONNECT_USER";
export const ERROR_DISCONNECT_USER = "ERROR_DISCONNECT_USER";

export const START_SWITCH_ACCOUNT = "START_SWITCH_ACCOUNT";
export const SUCCESS_SWITCH_ACCOUNT = "SUCCESS_SWITCH_ACCOUNT";
export const ERROR_SWITCH_ACCOUNT = "ERROR_SWITCH_ACCOUNT";

export const SET_USER_TYPE = "SET_USER_TYPE";

// User Socket

export const SOCKET_VERIFY_EMAIL = "SOCKET_VERIFY_EMAIL";
export const SOCKET_LOGOUT_USER = "SOCKET_LOGOUT_USER";
export const SOCKET_LOGIN_USER = "SOCKET_LOGIN_USER";
export const SOCKET_FOLLOWER_ADDED = "SOCKET_FOLLOWER_ADDED";
export const SOCKET_VERIFY_IDENTITY = "SOCKET_VERIFY_IDENTITY";
export const SOCKET_USER_ACCOUNT_FREEZED = "SOCKET_USER_ACCOUNT_FREEZED";
export const SOCKET_CONNECT_USER = "SOCKET_CONNECT_USER";
export const SOCKET_DISCONNECT_USER = "SOCKET_DISCONNECT_USER";
