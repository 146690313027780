import {
  ERROR_GET_POST_SAVED_FOR_LATER_CONFIG,
  ERROR_GET_QUEUE_COUNTERS,
  ERROR_GET_QUEUE_LIST,
  ERROR_GET_SAVED_FOR_LATER_POSTS,
  ERROR_TAKE_SCHEDULE_ACTION,
  ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
  START_GET_POST_SAVED_FOR_LATER_CONFIG,
  START_GET_QUEUE_COUNTERS,
  START_GET_QUEUE_LIST,
  START_GET_SAVED_FOR_LATER_POSTS,
  START_TAKE_SCHEDULE_ACTION,
  START_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
  SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG,
  SUCCESS_GET_QUEUE_COUNTERS,
  SUCCESS_GET_QUEUE_LIST,
  SUCCESS_GET_SAVED_FOR_LATER_POSTS,
  SUCCESS_TAKE_SCHEDULE_ACTION,
  SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
} from "Actions/QueueAction";
import {
  getQueueCountsAPI,
  getQueueListAPI,
  getSavedForLaterConfigAPI,
  getSavedForLaterPostsAPI,
  takeScheduleActionAPI,
  updateSavedForLaterConfigAPI,
} from "Api/QueueApi";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

function* getQueueCounterWorker({ payload }) {
  try {
    const res = yield call(getQueueCountsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_QUEUE_COUNTERS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_QUEUE_COUNTERS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getQueueListWorker({ payload }) {
  try {
    const res = yield call(getQueueListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_QUEUE_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_QUEUE_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* takeScheduleActionWorker({ id, payload }) {
  try {
    const res = yield call(takeScheduleActionAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_TAKE_SCHEDULE_ACTION,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_TAKE_SCHEDULE_ACTION,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getPostSavedForLaterConfigWorker() {
  try {
    const res = yield call(getSavedForLaterConfigAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_POST_SAVED_FOR_LATER_CONFIG,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updatePostSavedForLaterConfigWorker({ payload }) {
  try {
    const res = yield call(updateSavedForLaterConfigAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
        payload: res,
        data: payload,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSavedForLaterPostsWorker() {
  try {
    const res = yield call(getSavedForLaterPostsAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_SAVED_FOR_LATER_POSTS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_SAVED_FOR_LATER_POSTS,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* QueueWatcher() {
  yield takeEvery(START_GET_QUEUE_COUNTERS, getQueueCounterWorker);
  yield takeLatest(START_GET_QUEUE_LIST, getQueueListWorker);
  yield takeEvery(START_TAKE_SCHEDULE_ACTION, takeScheduleActionWorker);
  yield takeEvery(
    START_GET_POST_SAVED_FOR_LATER_CONFIG,
    getPostSavedForLaterConfigWorker
  );
  yield takeEvery(
    START_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
    updatePostSavedForLaterConfigWorker
  );
  yield takeEvery(START_GET_SAVED_FOR_LATER_POSTS, getSavedForLaterPostsWorker);
}
