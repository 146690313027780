import { combineReducers } from "redux";
import { cardReducer } from "./CardReducer";
import { chatReducer } from "./ChatReducer";
import { configReducer } from "./ConfigReducer";
import { globalProfileReducer } from "./GlobalProfileReducer";
import { listReducer } from "./ListReducer";
import { notificationReducer } from "./NotificationReducer";
import { payoutReducer } from "./PayoutReducer";
import { postReducer } from "./PostReducer";
import { searchReducer } from "./SearchReducer";
import { settingsReducer } from "./SettingReducer";
import { statementReducer } from "./StatementReducer";
import { streamReducer } from "./StreamReducer";
import { subscriptionReducer } from "./SubscriptionReducer";
import { userReducer } from "./UserReducer";
import { releaseFormReducer } from "./ReleaseFormReducer";
import { commonReducer } from "./CommonReducer";
import { bindReducer } from "easy-redux-http-call";
import { statisticsReducer } from "./StatisticsReducer";
import { queueReducer } from "./QueueReducer";
import { vaultReducer } from "./VaultReducer";
import { collabPostsReducer } from "./CollabPostsReducer";
import { agencyReducer } from "./AgencyReducer";

// export default combineReducers({
//   user: userReducer,
//   config: configReducer,
//   post: postReducer,
// });

const Reducers = (state = {}, action) => {
  return {
    user: userReducer(state.user, action, state),
    list: listReducer(state.list, action, state),
    config: configReducer(state.config, action, state),
    post: postReducer(state.post, action, state),
    chat: chatReducer(state.chat, action, state),
    notification: notificationReducer(state.notification, action, state),
    stream: streamReducer(state.stream, action, state),
    statement: statementReducer(state.statement, action, state),
    search: searchReducer(state.search, action, state),
    card: cardReducer(state.card, action, state),
    subscription: subscriptionReducer(state.subscription, action, state),
    // payout: payoutReducer(state.payout, action, state),
    payout: bindReducer(payoutReducer, { key: "payout" })(
      state.payout,
      action,
      state
    ),
    global: globalProfileReducer(state.global, action, state),
    settings: settingsReducer(state.settings, action, state),
    releaseForm: releaseFormReducer(state.releaseForm, action, state),
    common: commonReducer(state.common, action, state),
    // vijay: bindReducer(() => ({}), { key: "vijay" })(
    //   state.common,
    //   action,
    //   state
    // ),
    statistics: statisticsReducer(state.statistics, action, state),
    queue: queueReducer(state.queue, action, state),
    vault: vaultReducer(state.vault, action, state),
    collabPosts: collabPostsReducer(state.collabPosts, action, state),
    agency: agencyReducer(state.agency, action, state),
  };
};

export default Reducers;
