import {
  START_GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS,
  ERROR_GET_NOTIFICATIONS,
  START_READ_SINGLE_NOTIFICATION,
  SUCCESS_READ_SINGLE_NOTIFICATION,
  ERROR_READ_SINGLE_NOTIFICATION,
  START_READ_ALL_NOTIFICATION,
  SUCCESS_READ_ALL_NOTIFICATION,
  ERROR_READ_ALL_NOTIFICATION,
  SOCKET_ADD_NEW_NOTIFICATION,
  SOCKET_REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "Actions/NotificationAction";

const initialState = {
  getNotificationData: {
    isLoading: false,
    data: {},
    error: "",
  },
  readNotificationData: {
    isLoading: false,
    data: {},
    error: "",
  },
  readAllNotificationData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const notificationReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case START_GET_NOTIFICATIONS: {
      let oldNotifications = { ...state?.getNotificationData?.data };
      return {
        ...state,
        getNotificationData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldNotifications : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_NOTIFICATIONS: {
      let oldNotifications = { ...state?.getNotificationData?.data };
      if (oldNotifications?.notifications?.length > 0) {
        oldNotifications["notifications"] = [
          ...oldNotifications?.notifications,
          ...action?.payload?.notifications,
        ];
      } else {
        oldNotifications = action?.payload;
      }
      return {
        ...state,
        getNotificationData: {
          isLoading: false,
          data: oldNotifications,
          error: "",
        },
      };
    }
    case ERROR_GET_NOTIFICATIONS: {
      return {
        ...state,
        getNotificationData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_READ_SINGLE_NOTIFICATION: {
      return {
        ...state,
        readNotificationData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_READ_SINGLE_NOTIFICATION: {
      return {
        ...state,
        readNotificationData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_READ_SINGLE_NOTIFICATION: {
      return {
        ...state,
        readNotificationData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_READ_ALL_NOTIFICATION: {
      return {
        ...state,
        readAllNotificationData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_READ_ALL_NOTIFICATION: {
      return {
        ...state,
        readAllNotificationData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_READ_ALL_NOTIFICATION: {
      return {
        ...state,
        readAllNotificationData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    // Socket
    case SOCKET_ADD_NEW_NOTIFICATION: {
      let oldNotifications = { ...state?.getNotificationData?.data };
      oldNotifications["isNew"] = document.location.href.includes(
        "/notifications"
      )
        ? true
        : false;
      if (oldNotifications?.notifications?.length > 0) {
        oldNotifications["notifications"] = [
          action?.payload?.notification,
          ...oldNotifications?.notifications,
        ];
        oldNotifications["notification_count"] =
          oldNotifications?.notification_count + 1;
      } else {
        oldNotifications["notifications"] = [action?.payload?.notification];
        oldNotifications["notification_count"] = 1;
      }
      return {
        ...state,
        getNotificationData: {
          ...state.getNotificationData,
          data: oldNotifications,
        },
      };
    }
    
    // Socket
    case SOCKET_REMOVE_NOTIFICATION: {
      let oldNotifications2 = { ...state?.getNotificationData?.data };
      oldNotifications2.notifications = oldNotifications2.notifications?.filter(n => n.id !== action.payload.notification_id)
      return {
        ...state,
        getNotificationData: {
          ...state.getNotificationData,
          data: oldNotifications2,
        },
      };
    }

    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        getNotificationData: {
          ...state.getNotificationData,
          data : {
            ...state.getNotificationData.data,
            notifications :  state.getNotificationData?.data?.notifications?.map(item => {
              if(item.id === action.id){
                return {
                  ...item,
                  ...action.data
                }
              } else return item
            }),
          }
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
