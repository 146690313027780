export const RESET_VAULT_ACTION = "RESET_VAULT_ACTION";

export const SET_VAULT_CONFIG_DATA = "SET_VAULT_CONFIG_DATA";

export const START_GET_VAULT_LIST = "START_GET_VAULT_LIST";
export const SUCCESS_GET_VAULT_LIST = "SUCCESS_GET_VAULT_LIST";
export const ERROR_GET_VAULT_LIST = "ERROR_GET_VAULT_LIST";

export const START_GET_INNER_VAULT = "START_GET_INNER_VAULT";
export const SUCCESS_GET_INNER_VAULT = "SUCCESS_GET_INNER_VAULT";
export const ERROR_GET_INNER_VAULT = "ERROR_GET_INNER_VAULT";

export const START_CREATE_VAULT_LIST = "START_CREATE_VAULT_LIST";
export const SUCCESS_CREATE_VAULT_LIST = "SUCCESS_CREATE_VAULT_LIST";
export const ERROR_CREATE_VAULT_LIST = "ERROR_CREATE_VAULT_LIST";

export const START_UPDATE_VAULT_LIST = "START_UPDATE_VAULT_LIST";
export const SUCCESS_UPDATE_VAULT_LIST = "SUCCESS_UPDATE_VAULT_LIST";
export const ERROR_UPDATE_VAULT_LIST = "ERROR_UPDATE_VAULT_LIST";

export const START_DELETE_VAULT_LIST = "START_DELETE_VAULT_LIST";
export const SUCCESS_DELETE_VAULT_LIST = "SUCCESS_DELETE_VAULT_LIST";
export const ERROR_DELETE_VAULT_LIST = "ERROR_DELETE_VAULT_LIST";

export const START_GET_SINGLE_VAULT_LIST = "START_GET_SINGLE_VAULT_LIST";
export const SUCCESS_GET_SINGLE_VAULT_LIST = "SUCCESS_GET_SINGLE_VAULT_LIST";
export const ERROR_GET_SINGLE_VAULT_LIST = "ERROR_GET_SINGLE_VAULT_LIST";

export const START_CHANGE_VAULT_LIST_ORDER = "START_CHANGE_VAULT_LIST_ORDER";
export const SUCCESS_CHANGE_VAULT_LIST_ORDER =
  "SUCCESS_CHANGE_VAULT_LIST_ORDER";
export const ERROR_CHANGE_VAULT_LIST_ORDER = "ERROR_CHANGE_VAULT_LIST_ORDER";

export const START_GET_VAULT_MEDIA_LIST = "START_GET_VAULT_MEDIA_LIST";
export const SUCCESS_GET_VAULT_MEDIA_LIST = "SUCCESS_GET_VAULT_MEDIA_LIST";
export const ERROR_GET_VAULT_MEDIA_LIST = "ERROR_GET_VAULT_MEDIA_LIST";

export const START_DELETE_VAULT_MEDIA = "START_DELETE_VAULT_MEDIA";
export const SUCCESS_DELETE_VAULT_MEDIA = "SUCCESS_DELETE_VAULT_MEDIA";
export const ERROR_DELETE_VAULT_MEDIA = "ERROR_DELETE_VAULT_MEDIA";

export const START_CHANGE_MEDIA_CATEGORY = "START_CHANGE_MEDIA_CATEGORY";
export const SUCCESS_CHANGE_MEDIA_CATEGORY = "SUCCESS_CHANGE_MEDIA_CATEGORY";
export const ERROR_CHANGE_MEDIA_CATEGORY = "ERROR_CHANGE_MEDIA_CATEGORY";

export const START_GET_VAULT_MEDIA_BY_ID = "START_GET_VAULT_MEDIA_BY_ID";
export const SUCCESS_GET_VAULT_MEDIA_BY_ID = "SUCCESS_GET_VAULT_MEDIA_BY_ID";
export const ERROR_GET_VAULT_MEDIA_BY_ID = "ERROR_GET_VAULT_MEDIA_BY_ID";
