import {
  RESET_COMMON_REDUCER,
  START_GET_USER_HINTS,
  SUCCESS_GET_USER_HINTS,
  ERROR_GET_USER_HINTS,
  START_DISMISS_USER_HINTS,
  SUCCESS_DISMISS_USER_HINTS,
  ERROR_DISMISS_USER_HINTS,
  SOCKET_USER_HINTS_UPDATED,
  SOCKET_USER_HINTS_DELETED,
} from "Actions/CommonAction";

const initialState = {
  userHintsData: {
    isLoading: false,
    data: {
      count: 0,
      rows: [],
    },
    error: "",
  },
  dismissUserHintData: {
    isLoading: false,
    data: {},
    error: "",
  },
  selectedPayoutSystem: {},
};

export const commonReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_COMMON_REDUCER: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_GET_USER_HINTS: {
      return {
        ...state,
        userHintsData: {
          ...state.userHintsData,
          ...action.payload,
          data:
            action.payload?.offset === 0
              ? {
                  count: 0,
                  rows: [],
                }
              : state.userHintsData.data,
          isLoading: true,
        },
      };
    }
    case SUCCESS_GET_USER_HINTS: {
      return {
        ...state,
        userHintsData: {
          ...action.payload,
          data:
            action.payload?.offset === 0
              ? action.payload?.data
              : {
                  count: state.userHintsData.data.count,
                  rows: state.userHintsData.data.rows.concat(
                    action.payload?.data?.rows
                  ),
                },
          isLoading: false,
        },
      };
    }
    case ERROR_GET_USER_HINTS: {
      return {
        ...state,
        userHintsData: {
          ...state.userHintsData,
          ...action.payload,
          isLoading: false,
        },
      };
    }
    case START_DISMISS_USER_HINTS: {
      return {
        ...state,
        dismissUserHintData: {
          isLoading: true,
          data: {
            id: action?.payload?.id,
          },
          error: "",
        },
      };
    }
    case SUCCESS_DISMISS_USER_HINTS: {
      const obj = { ...state.userHintsData };
      obj["data"]["rows"] = obj?.data?.rows?.filter(
        (item) => item?.id !== action?.payload?.id
      );
      obj["data"]["count"] = obj?.data?.count - 1;
      return {
        ...state,
        dismissUserHintData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userHintsData: obj,
      };
    }
    case ERROR_DISMISS_USER_HINTS: {
      return {
        ...state,
        dismissUserHintData: {
          isLoading: false,
          data: {},
          error: "",
        },
      };
    }
    case SOCKET_USER_HINTS_UPDATED: {
      let obj = { ...state.userHintsData };
      const { post_id, rfDoc = [], newUserHints = [] } = action?.payload;
      obj["data"]["rows"] = obj?.data?.rows?.filter((item) => {
        if (item?.post_id === post_id) {
          if (rfDoc?.includes(item?.release_form_id)) {
            return true;
          }
          obj["data"]["rows"] = obj?.data?.count - 1;
          return false;
        }
        return true;
      });
      obj["data"]["rows"] = obj?.data?.rows?.concat(newUserHints);
      obj["data"]["count"] = obj?.data?.count + newUserHints?.length;
      return {
        ...state,
        userHintsData: obj,
      };
    }
    case SOCKET_USER_HINTS_DELETED: {
      let obj = { ...state.userHintsData };
      const { release_form_id } = action?.payload;
      let deletedHintsCount = 0;
      obj["data"]["rows"] = obj?.data?.rows?.filter((item) => {
        if (item?.release_form_id !== release_form_id) {
          return true;
        }
        deletedHintsCount++;
        return false;
      });
      obj["data"]["count"] = obj?.data?.count - deletedHintsCount;
      return {
        ...state,
        userHintsData: obj,
      };
    }
    default: {
      return {
        ...(state || initialState),
      };
    }
  }
};
