import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  START_GET_CHAT_LIST,
  SUCCESS_GET_CHAT_LIST,
  ERROR_GET_CHAT_LIST,
  START_GET_NEW_MESSAGE_LIST,
  SUCCESS_GET_NEW_MESSAGE_LIST,
  ERROR_GET_NEW_MESSAGE_LIST,
  START_CREATE_NEW_CHAT,
  SUCCESS_CREATE_NEW_CHAT,
  ERROR_CREATE_NEW_CHAT,
  START_GET_CHAT_CONVERSATION,
  SUCCESS_GET_CHAT_CONVERSATION,
  ERROR_GET_CHAT_CONVERSATION,
  START_SEND_MESSAGE,
  SUCCESS_SEND_MESSAGE,
  ERROR_SEND_MESSAGE,
  START_READ_MESSAGE,
  SUCCESS_READ_MESSAGE,
  ERROR_READ_MESSAGE,
  START_DELETE_CHAT,
  SUCCESS_DELETE_CHAT,
  ERROR_DELETE_CHAT,
  START_DELETE_CHAT_MESSAGES,
  SUCCESS_DELETE_CHAT_MESSAGES,
  ERROR_DELETE_CHAT_MESSAGES,
  START_PURCHASE_MESSAGE,
  SUCCESS_PURCHASE_MESSAGE,
  ERROR_PURCHASE_MESSAGE,
  START_GET_SINGLE_MASS_MESSAGE,
  SUCCESS_GET_SINGLE_MASS_MESSAGE,
  ERROR_GET_SINGLE_MASS_MESSAGE,
  START_UPDATE_MASS_MESSAGE,
  SUCCESS_UPDATE_MASS_MESSAGE,
  ERROR_UPDATE_MASS_MESSAGE,
  SUCCESS_GET_CHAT_CONVERSATION_CATCHED,
  SUCCESS_GET_CHAT_LIST_CACHED,
} from "../Actions/ChatAction";

import {
  createNewChatAPI,
  getChatListAPI,
  getNewMessageListAPI,
  getChatConversationAPI,
  sendMessageAPI,
  readMessageAPI,
  deleteChatAPI,
  getChatMediaUrlAPI,
  uploadChatMediaOnAwsAPI,
  deleteChatMessageAPI,
  purchaseMessageAPI,
  getSingleMassMessageAPI,
  updateMassMessageAPI,
} from "../Api/ChatApi";

function* getChatListWorker({ payload }) {
  try {
    if (payload?.filter === "all" && payload?.order_by === "recent" && payload?.offset === 0 && payload?.search === "") {
      const chatListData = yield select((state) => state.chat.chatListDataCached);
      yield put({
        type: SUCCESS_GET_CHAT_LIST_CACHED,
        payload: {
          status : true,
          chat_count: chatListData?.total,
          chats: chatListData?.list,
        },
        offset: 0,
      });
    }

    const response = yield call(getChatListAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_CHAT_LIST,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_CHAT_LIST,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getNewMessageListWorker({ payload }) {
  try {
    const response = yield call(getNewMessageListAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_NEW_MESSAGE_LIST,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_NEW_MESSAGE_LIST,
        payload: response,
      });
    }
  } catch (error) {}
}

function* createNewChatWorker({ payload }) {
  try {
    const response = yield call(createNewChatAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_CREATE_NEW_CHAT,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_CREATE_NEW_CHAT,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getChatConversationWorker({ chat_id, payload }) {
  try {
    if (payload?.offset === 0) {
      const res = yield select(
        (state) => state.chat.chatConversationDataCatched
      );

      if (res[chat_id]) {
        yield put({
          type: SUCCESS_GET_CHAT_CONVERSATION_CATCHED,
          payload: res[chat_id],
          offset: 0,
          chat_id: chat_id,
        });
      }
    }

    const response = yield call(getChatConversationAPI, chat_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_CHAT_CONVERSATION,
        payload: response,
        offset: payload?.offset,
        chat_id: chat_id,
      });
    } else {
      yield put({
        type: ERROR_GET_CHAT_CONVERSATION,
        payload: response,
      });
    }
  } catch (error) {}
}

function* sendMessageWorker({ payload }) {
  try {
    if (payload?.chat_image?.length > 0 || payload?.chat_video?.length > 0) {
      const response = yield call(getChatMediaUrlAPI, {
        chat_room_id: payload?.chat_room_id,
        ...(payload?.chat_image?.length > 0 && {
          images: payload?.chat_image,
        }),
        ...(payload?.chat_video?.length > 0 && {
          videos: payload?.chat_video,
        }),
      });
      if (response?.status) {
        const response1 = yield call(
          uploadChatMediaOnAwsAPI,
          response?.image_signed_urls
            ?.concat(response?.video_signed_urls)
            ?.map((item) => item?.url),
          (payload?.chat_image_media || []).concat(
            payload?.chat_video_media || []
          )
        );
        if (response1) {
          const response2 = yield call(sendMessageAPI, {
            chat_room_id: payload?.chat_room_id,
            message: payload?.message,
            video_keys: response?.video_signed_urls?.map(
              (item) => item?.file_key
            ),
            image_keys: response?.image_signed_urls?.map(
              (item) => item?.file_key
            ),
            purchase_price: payload?.purchase_price,
          });
          if (response2.status) {
            yield put({
              type: SUCCESS_SEND_MESSAGE,
              payload: response2,
            });
          } else {
            yield put({
              type: ERROR_SEND_MESSAGE,
              payload: response2,
            });
            yield put({
              type: ADD_NEW_NOTIFICATION,
              payload: {
                message: "Something went wrong",
                type: "error",
              },
            });
          }
        }
      } else {
        yield put({
          type: ERROR_SEND_MESSAGE,
          payload: response,
        });
        yield put({
          type: ADD_NEW_NOTIFICATION,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      }
    } else {
      const response3 = yield call(sendMessageAPI, {
        chat_room_id: payload?.chat_room_id,
        message: payload?.message,
        purchase_price: payload?.purchase_price,
      });
      if (response3.status) {
        yield put({
          type: SUCCESS_SEND_MESSAGE,
          payload: response3,
        });
      } else {
        yield put({
          type: ERROR_SEND_MESSAGE,
          payload: response3,
        });
      }
    }
  } catch (error) {}
}

function* readMessageWorker({ payload, read_type }) {
  try {
    const response = yield call(readMessageAPI, payload, read_type);
    if (response.status) {
      yield put({
        type: SUCCESS_READ_MESSAGE,
        payload: response,
        chat_id: payload?.chat_id,
        read_type,
      });
    } else {
      yield put({
        type: ERROR_READ_MESSAGE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* deleteChatWorker({ payload }) {
  try {
    const response = yield call(deleteChatAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_CHAT,
        payload: response,
        chat_ids: payload?.room_ids,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Chat deleted successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_CHAT,
        payload: response,
      });
    }
  } catch (error) {}
}

function* deleteChatMessagesWorker({ chat_id, payload }) {
  try {
    const response = yield call(deleteChatMessageAPI, chat_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_CHAT_MESSAGES,
        payload: response,
        message_ids: payload?.chat_message_id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_CHAT_MESSAGES,
        payload: response,
      });
    }
  } catch (error) {}
}

function* purchaseMessageWorker({ payload }) {
  try {
    const res = yield call(purchaseMessageAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_PURCHASE_MESSAGE,
        payload: res,
        message_id: payload?.chat_message_id,
        data: payload,
      });
    } else {
      yield put({
        type: ERROR_PURCHASE_MESSAGE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong! try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getSingleMassMessageWorker({ id, payload }) {
  try {
    const res = yield call(getSingleMassMessageAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_SINGLE_MASS_MESSAGE,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_SINGLE_MASS_MESSAGE,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateMassMessageWorker({ id, payload }) {
  try {
    const res = yield call(updateMassMessageAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_MASS_MESSAGE,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_MASS_MESSAGE,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* ChatWatcher() {
  yield takeEvery(START_GET_CHAT_LIST, getChatListWorker);
  yield takeEvery(START_GET_NEW_MESSAGE_LIST, getNewMessageListWorker);
  yield takeEvery(START_CREATE_NEW_CHAT, createNewChatWorker);
  yield takeEvery(START_GET_CHAT_CONVERSATION, getChatConversationWorker);
  yield takeEvery(START_SEND_MESSAGE, sendMessageWorker);
  yield takeEvery(START_READ_MESSAGE, readMessageWorker);
  yield takeEvery(START_DELETE_CHAT, deleteChatWorker);
  yield takeEvery(START_DELETE_CHAT_MESSAGES, deleteChatMessagesWorker);
  yield takeEvery(START_PURCHASE_MESSAGE, purchaseMessageWorker);
  yield takeEvery(START_GET_SINGLE_MASS_MESSAGE, getSingleMassMessageWorker);
  yield takeEvery(START_UPDATE_MASS_MESSAGE, updateMassMessageWorker);
}
