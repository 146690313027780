import {
  ERROR_GET_STATEMENT_EARNING_STATISTICS,
  START_GET_STATEMENT_EARNING_STATISTICS,
  SUCCESS_GET_STATEMENT_EARNING_STATISTICS,
  SET_STATISTICS_FILTER,
  RESET_STATISTICS_DATA,
  START_GET_STATEMENT_EARNING_LIST,
  SUCCESS_GET_STATEMENT_EARNING_LIST,
  ERROR_GET_STATEMENT_EARNING_LIST,
  START_GET_STATISTICS_CONFIG,
  SUCCESS_GET_STATISTICS_CONFIG,
  ERROR_GET_STATISTICS_CONFIG,
  START_GET_STATEMENT_PAYOUT_STATISTICS,
  SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS,
  ERROR_GET_STATEMENT_PAYOUT_STATISTICS,
  START_GET_STATEMENT_PAYOUT_LIST,
  SUCCESS_GET_STATEMENT_PAYOUT_LIST,
  ERROR_GET_STATEMENT_PAYOUT_LIST,
  START_GET_STATEMENT_CHARGEBACK_STATISTICS,
  SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS,
  ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS,
  START_GET_STATEMENT_CHARGEBACK_LIST,
  SUCCESS_GET_STATEMENT_CHARGEBACK_LIST,
  ERROR_GET_STATEMENT_CHARGEBACK_LIST,
  START_GET_STATEMENT_REFERRALS_STATISTICS,
  SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS,
  ERROR_GET_STATEMENT_REFERRALS_STATISTICS,
  START_GET_STATEMENT_REFERRALS_LIST,
  SUCCESS_GET_STATEMENT_REFERRALS_LIST,
  ERROR_GET_STATEMENT_REFERRALS_LIST,
} from "Actions/StatisticsAction";

const initialState = {
  statementEarningsStatisticsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementEarningList: {
    isLoading: false,
    data: {},
    error: "",
  },
  statisticsConfig: {
    isLoading: false,
    data: {},
    error: "",
  },
  statisticsFilter: {
    type: "30",
    date: {},
    isYear: false,
  },
  statementPayoutStatisticsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementPayoutList: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementChargebackStatisticsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementChargebackList: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementReferralsStatisticsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementReferralsList: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const statisticsReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_STATISTICS_DATA: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case SET_STATISTICS_FILTER: {
      return {
        ...state,
        [action.key]: {
          ...state?.[action?.key],
          ...action.payload,
          isYear: action?.payload?.isYear ? true : false,
        },
      };
    }
    case START_GET_STATEMENT_EARNING_STATISTICS: {
      return {
        ...state,
        statementEarningsStatisticsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_EARNING_STATISTICS: {
      return {
        ...state,
        statementEarningsStatisticsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_EARNING_STATISTICS: {
      return {
        ...state,
        statementEarningsStatisticsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_EARNING_LIST: {
      let oldList = { ...state?.statementEarningList?.data };
      return {
        ...state,
        statementEarningList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_EARNING_LIST: {
      let oldList = { ...state?.statementEarningList?.data };
      if (oldList?.rows?.length > 0) {
        oldList.rows = [...oldList?.rows, ...action?.payload?.rows];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        statementEarningList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_EARNING_LIST: {
      return {
        ...state,
        statementEarningList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATISTICS_CONFIG: {
      return {
        ...state,
        statisticsConfig: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATISTICS_CONFIG: {
      return {
        ...state,
        statisticsConfig: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATISTICS_CONFIG: {
      return {
        ...state,
        statisticsConfig: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_PAYOUT_STATISTICS: {
      return {
        ...state,
        statementPayoutStatisticsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS: {
      return {
        ...state,
        statementPayoutStatisticsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_PAYOUT_STATISTICS: {
      return {
        ...state,
        statementPayoutStatisticsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_PAYOUT_LIST: {
      let oldList = { ...state?.statementPayoutList?.data };
      return {
        ...state,
        statementPayoutList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_PAYOUT_LIST: {
      let oldList = { ...state?.statementPayoutList?.data };
      if (oldList?.rows?.length > 0) {
        oldList.rows = [...oldList?.rows, ...action?.payload?.rows];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        statementPayoutList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_PAYOUT_LIST: {
      return {
        ...state,
        statementPayoutList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_CHARGEBACK_STATISTICS: {
      return {
        ...state,
        statementChargebackStatisticsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS: {
      return {
        ...state,
        statementChargebackStatisticsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS: {
      return {
        ...state,
        statementChargebackStatisticsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_CHARGEBACK_LIST: {
      let oldList = { ...state?.statementChargebackList?.data };
      return {
        ...state,
        statementChargebackList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_CHARGEBACK_LIST: {
      let oldList = { ...state?.statementChargebackList?.data };
      if (oldList?.rows?.length > 0) {
        oldList.rows = [...oldList?.rows, ...action?.payload?.rows];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        statementChargebackList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_CHARGEBACK_LIST: {
      return {
        ...state,
        statementChargebackList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_REFERRALS_STATISTICS: {
      return {
        ...state,
        statementReferralsStatisticsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS: {
      return {
        ...state,
        statementReferralsStatisticsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_REFERRALS_STATISTICS: {
      return {
        ...state,
        statementReferralsStatisticsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_REFERRALS_LIST: {
      let oldList = { ...state?.statementReferralsList?.data };
      return {
        ...state,
        statementReferralsList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_REFERRALS_LIST: {
      let oldList = { ...state?.statementReferralsList?.data };
      if (oldList?.rows?.length > 0) {
        oldList.rows = [...oldList?.rows, ...action?.payload?.rows];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        statementReferralsList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_REFERRALS_LIST: {
      return {
        ...state,
        statementReferralsList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};
