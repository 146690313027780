export const SET_STATISTICS_FILTER = "SET_STATISTICS_FILTER";

export const RESET_STATISTICS_DATA = "RESET_STATISTICS_DATA";

export const START_GET_STATEMENT_EARNING_STATISTICS =
  "START_GET_STATEMENT_EARNING_STATISTICS";
export const SUCCESS_GET_STATEMENT_EARNING_STATISTICS =
  "SUCCESS_GET_STATEMENT_EARNING_STATISTICS";
export const ERROR_GET_STATEMENT_EARNING_STATISTICS =
  "ERROR_GET_STATEMENT_EARNING_STATISTICS";

export const START_GET_STATEMENT_EARNING_LIST =
  "START_GET_STATEMENT_EARNING_LIST";
export const SUCCESS_GET_STATEMENT_EARNING_LIST =
  "SUCCESS_GET_STATEMENT_EARNING_LIST";
export const ERROR_GET_STATEMENT_EARNING_LIST =
  "ERROR_GET_STATEMENT_EARNING_LIST";

export const START_GET_STATISTICS_CONFIG = "START_GET_STATISTICS_CONFIG";
export const SUCCESS_GET_STATISTICS_CONFIG = "SUCCESS_GET_STATISTICS_CONFIG";
export const ERROR_GET_STATISTICS_CONFIG = "ERROR_GET_STATISTICS_CONFIG";

export const START_GET_STATEMENT_PAYOUT_STATISTICS =
  "START_GET_STATEMENT_PAYOUT_STATISTICS";
export const SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS =
  "SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS";
export const ERROR_GET_STATEMENT_PAYOUT_STATISTICS =
  "ERROR_GET_STATEMENT_PAYOUT_STATISTICS";

export const START_GET_STATEMENT_PAYOUT_LIST =
  "START_GET_STATEMENT_PAYOUT_LIST";
export const SUCCESS_GET_STATEMENT_PAYOUT_LIST =
  "SUCCESS_GET_STATEMENT_PAYOUT_LIST";
export const ERROR_GET_STATEMENT_PAYOUT_LIST =
  "ERROR_GET_STATEMENT_PAYOUT_LIST";

export const START_GET_STATEMENT_CHARGEBACK_STATISTICS =
  "START_GET_STATEMENT_CHARGEBACK_STATISTICS";
export const SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS =
  "SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS";
export const ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS =
  "ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS";

export const START_GET_STATEMENT_CHARGEBACK_LIST =
  "START_GET_STATEMENT_CHARGEBACK_LIST";
export const SUCCESS_GET_STATEMENT_CHARGEBACK_LIST =
  "SUCCESS_GET_STATEMENT_CHARGEBACK_LIST";
export const ERROR_GET_STATEMENT_CHARGEBACK_LIST =
  "ERROR_GET_STATEMENT_CHARGEBACK_LIST";

export const START_GET_STATEMENT_REFERRALS_STATISTICS =
  "START_GET_STATEMENT_REFERRALS_STATISTICS";
export const SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS =
  "SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS";
export const ERROR_GET_STATEMENT_REFERRALS_STATISTICS =
  "ERROR_GET_STATEMENT_REFERRALS_STATISTICS";

export const START_GET_STATEMENT_REFERRALS_LIST =
  "START_GET_STATEMENT_REFERRALS_LIST";
export const SUCCESS_GET_STATEMENT_REFERRALS_LIST =
  "SUCCESS_GET_STATEMENT_REFERRALS_LIST";
export const ERROR_GET_STATEMENT_REFERRALS_LIST =
  "ERROR_GET_STATEMENT_REFERRALS_LIST";
