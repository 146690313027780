import API from "service";
import { generateDynamicEndpoint, getClearObject } from "utils/generalUtils";
import {
  POST_SIGNED_RELEASE_FORM_ENDPOINT,
  GET_SIGNED_RELEASE_FORM_ENDPOINT,
  UPDATE_SIGNED_RELEASE_FORM_ENDPOINT,
  UPDATE_RF_DEFAULT_VISIBILITY_ENDPOINT,
  CREATE_INVITATION_LINK_ENDPOINT,
  UPDATE_INVITATION_LINK_ENDPOINT,
  UPDATE_LINK_VISIBILITY_ENDPOINT,
  GET_TAGGED_USERS_ENDPOINT,
  VERIFY_GUEST_TOKEN_ENDPOINT,
} from "./endpoints";

export const postSignedReleaseForm = (payload) => {
  return API.post(POST_SIGNED_RELEASE_FORM_ENDPOINT, payload);
};

export const getSignedReleaseForm = (queryParams) => {
  return API.get(GET_SIGNED_RELEASE_FORM_ENDPOINT, getClearObject(queryParams));
};

export const updateSignedReleaseForm = (id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_SIGNED_RELEASE_FORM_ENDPOINT, { id }),
    payload
  );
};

export const updateRFDefaultVisibility = (payload) => {
  return API.post(UPDATE_RF_DEFAULT_VISIBILITY_ENDPOINT, payload);
};

export const createInvitationLinkAPI = (payload) => {
  return API.post(CREATE_INVITATION_LINK_ENDPOINT, payload);
};

export const updateInvitationLinkAPI = (id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_INVITATION_LINK_ENDPOINT, { id }),
    payload
  );
};

export const updateLinkVisibilityAPI = (payload) => {
  return API.post(UPDATE_LINK_VISIBILITY_ENDPOINT, payload);
};

export const getTaggedUsersAPI = (queryParams) => {
  return API.get(GET_TAGGED_USERS_ENDPOINT, queryParams);
};

export const verifyGuestTokenAPI = (payload) => {
  return API.post(VERIFY_GUEST_TOKEN_ENDPOINT, payload);
};
