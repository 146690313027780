import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_ADD_USERS_TO_LIST,
  ERROR_CLEAR_LIST,
  ERROR_CREATE_LIST,
  ERROR_DELETE_LIST,
  ERROR_GET_ADD_USERS_LIST,
  ERROR_GET_LIST,
  ERROR_GET_LIST_USERS,
  ERROR_GET_SINGLE_LIST,
  ERROR_UPDATE_LIST,
  START_ADD_USERS_TO_LIST,
  START_CLEAR_LIST,
  START_CREATE_LIST,
  START_DELETE_LIST,
  START_GET_ADD_USERS_LIST,
  START_GET_LIST,
  START_GET_LIST_USERS,
  START_GET_SINGLE_LIST,
  START_UPDATE_LIST,
  SUCCESS_ADD_USERS_TO_LIST,
  SUCCESS_CLEAR_LIST,
  SUCCESS_CREATE_LIST,
  SUCCESS_DELETE_LIST,
  SUCCESS_GET_ADD_USERS_LIST,
  SUCCESS_GET_LIST,
  SUCCESS_GET_LIST_USERS,
  SUCCESS_GET_SINGLE_LIST,
  SUCCESS_UPDATE_LIST,
} from "Actions/ListActions";
import {
  addUsersToListAPI,
  clearListAPI,
  createListAPI,
  deleteListAPI,
  getAddUsersListAPI,
  getListAPI,
  getListUsersAPI,
  getSingleListAPI,
  updateListAPI,
} from "Api/ListApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* createListWorker({ payload }) {
  try {
    const res = yield call(createListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getListWorker({ payload }) {
  try {
    const res = yield call(getListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateListWorker({ id, payload }) {
  try {
    const res = yield call(updateListAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* clearListWorker({ id }) {
  try {
    const res = yield call(clearListAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_CLEAR_LIST,
        payload: res,
        list_id: id,
      });
    } else {
      yield put({
        type: ERROR_CLEAR_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteListWorker({ id }) {
  try {
    const res = yield call(deleteListAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_LIST,
        payload: res,
        list_id: id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSingleListWorker({ id }) {
  try {
    const res = yield call(getSingleListAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_SINGLE_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_SINGLE_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getListUsersWorker({ id, payload }) {
  try {
    const res = yield call(getListUsersAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_LIST_USERS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_LIST_USERS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getAddUsersListWorker({ payload }) {
  try {
    const res = yield call(getAddUsersListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_ADD_USERS_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_ADD_USERS_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* addUsersToListWorker({ payload }) {
  try {
    const res = yield call(addUsersToListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_ADD_USERS_TO_LIST,
        payload: res,
        listData: payload,
      });
    } else {
      yield put({
        type: ERROR_ADD_USERS_TO_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* ListWatcher() {
  yield takeEvery(START_CREATE_LIST, createListWorker);
  yield takeEvery(START_GET_LIST, getListWorker);
  yield takeEvery(START_UPDATE_LIST, updateListWorker);
  yield takeEvery(START_CLEAR_LIST, clearListWorker);
  yield takeEvery(START_DELETE_LIST, deleteListWorker);
  yield takeEvery(START_GET_SINGLE_LIST, getSingleListWorker);
  yield takeEvery(START_GET_LIST_USERS, getListUsersWorker);
  yield takeEvery(START_GET_ADD_USERS_LIST, getAddUsersListWorker);
  yield takeEvery(START_ADD_USERS_TO_LIST, addUsersToListWorker);
}
