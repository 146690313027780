import API from "service";
import {
  GET_NOTIFICATION_ENDPOINT,
  READ_ALL_NOTIFICATION_ENDPOINT,
  READ_SINGLE_NOTIFICATION_ENDPOINT,
} from "./endpoints";

export const getNotificationAPI = (payload) => {
  return API.post(GET_NOTIFICATION_ENDPOINT, payload);
};

export const readSingleNotificationAPI = (payload) => {
  return API.post(READ_SINGLE_NOTIFICATION_ENDPOINT, payload);
};

export const readAllNotificationAPI = () => {
  return API.post(READ_ALL_NOTIFICATION_ENDPOINT);
};
