import {
  ERROR_GET_MY_REFERRALS,
  START_GET_MY_REFERRALS,
  SUCCESS_GET_MY_REFERRALS,
} from "Actions/StatementAction";
import { getMyReferralsAPI } from "Api/StatementApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* getMyReferralsWorker({ payload }) {
  try {
    const response = yield call(getMyReferralsAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_MY_REFERRALS,
        payload: response,
        referalType: payload?.type,
      });
    } else {
      yield put({
        type: ERROR_GET_MY_REFERRALS,
        payload: response,
        referalType: payload?.type,
      });
    }
  } catch (error) {}
}

export function* StatementWatcher() {
  yield takeEvery(START_GET_MY_REFERRALS, getMyReferralsWorker);
}
