import API from "service";
import {
  SEARCH_USER_AND_POST_ENDPOINT,
  SEARCH_USER_ENDPOINT,
} from "./endpoints";

export const searchAPI = (payload) => {
  return API.post(SEARCH_USER_AND_POST_ENDPOINT, payload);
};

export const searchUserAPI = (payload) => {
  return API.post(SEARCH_USER_ENDPOINT, payload);
};
