import {
  START_CREATE_LIST,
  SUCCESS_CREATE_LIST,
  ERROR_CREATE_LIST,
  START_GET_LIST,
  SUCCESS_GET_LIST,
  ERROR_GET_LIST,
  RESET_LIST_STATE,
  START_UPDATE_LIST,
  SUCCESS_UPDATE_LIST,
  ERROR_UPDATE_LIST,
  START_CLEAR_LIST,
  SUCCESS_CLEAR_LIST,
  ERROR_CLEAR_LIST,
  START_DELETE_LIST,
  SUCCESS_DELETE_LIST,
  ERROR_DELETE_LIST,
  START_GET_SINGLE_LIST,
  SUCCESS_GET_SINGLE_LIST,
  ERROR_GET_SINGLE_LIST,
  START_GET_LIST_USERS,
  SUCCESS_GET_LIST_USERS,
  ERROR_GET_LIST_USERS,
  START_GET_ADD_USERS_LIST,
  SUCCESS_GET_ADD_USERS_LIST,
  ERROR_GET_ADD_USERS_LIST,
  START_ADD_USERS_TO_LIST,
  SUCCESS_ADD_USERS_TO_LIST,
  ERROR_ADD_USERS_TO_LIST,
} from "Actions/ListActions";
import { SUCCESS_SUBSCRIPTION_ACTION } from "Actions/SubscriptionAction";
import {
  SOCKET_LOGIN_USER,
  SOCKET_LOGOUT_USER,
  SUCCESS_BLOCK_USER,
  SUCCESS_UNBLOCK_USER,
} from "Actions/UserAction";
import { useParams } from "react-router-dom";

const initialState = {
  createListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateListData: {
    isLoading: false,
    data: {},
    error: "",
    updated: false,
  },
  clearListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getSingleListData: {
    isLoading: false,
    data: {},
    error: "",
    notFound: false,
  },
  getListUsersData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getAddUsersList: {
    isLoading: false,
    data: {},
    error: "",
  },
  addUsersToListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  refreshCounts: false,
};

export const listReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_LIST_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }

    case START_CREATE_LIST: {
      return {
        ...state,
        createListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_CREATE_LIST: {
      let oldList = { ...state?.getListData?.data };
      oldList["data"] = [action.payload?.data, ...oldList?.data];

      return {
        ...state,
        createListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getListData: {
          ...state.getListData,
          data: oldList,
        },
      };
    }

    case ERROR_CREATE_LIST: {
      return {
        ...state,
        createListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_LIST: {
      return {
        ...state,
        getListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_GET_LIST: {
      return {
        ...state,
        getListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }

    case ERROR_GET_LIST: {
      return {
        ...state,
        getListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_LIST: {
      return {
        ...state,
        updateListData: {
          isLoading: true,
          data: {},
          error: "",
          updated: false,
        },
      };
    }
    case SUCCESS_UPDATE_LIST: {
      let oldSingleList = { ...state?.getSingleListData?.data };
      oldSingleList["data"] = {
        ...oldSingleList?.data,
        ...action?.payload?.data,
      };

      let oldList = { ...state?.getListData?.data };
      if (oldList?.data?.length) {
        oldList["data"] = oldList?.data?.map((item) => {
          if (item?.id == action?.payload?.data?.id) {
            return {
              ...item,
              ...action?.payload?.data,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        updateListData: {
          isLoading: false,
          data: action.payload,
          error: "",
          updated: true,
        },
        getListData: {
          ...state.getListData,
          data: oldList,
        },
        getSingleListData: {
          ...state.getSingleListData,
          data: oldSingleList,
        },
      };
    }
    case ERROR_UPDATE_LIST: {
      return {
        ...state,
        updateListData: {
          isLoading: false,
          data: {},
          error: action.payload,
          updated: false,
        },
      };
    }

    case START_CLEAR_LIST: {
      return {
        ...state,
        clearListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CLEAR_LIST: {
      let oldList = { ...state?.getListUsersData };
      oldList["data"] = {};

      return {
        ...state,
        clearListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getListUsersData: oldList,
      };
    }
    case ERROR_CLEAR_LIST: {
      return {
        ...state,
        clearListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DELETE_LIST: {
      return {
        ...state,
        deleteListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_LIST: {
      return {
        ...state,
        deleteListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_LIST: {
      return {
        ...state,
        deleteListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_SINGLE_LIST: {
      let oldData = { ...state?.getSingleListData?.data };
      return {
        ...state,
        getSingleListData: {
          isLoading: action?.isRefresh ? false : true,
          data: action?.isRefresh ? oldData : {},
          error: "",
          notFound: false,
        },
      };
    }
    case SUCCESS_GET_SINGLE_LIST: {
      return {
        ...state,
        getSingleListData: {
          isLoading: false,
          data: action.payload,
          error: "",
          notFound: false,
        },
      };
    }
    case ERROR_GET_SINGLE_LIST: {
      return {
        ...state,
        getSingleListData: {
          isLoading: false,
          data: {},
          error: action.payload,
          notFound: true,
        },
      };
    }

    case START_GET_LIST_USERS: {
      let oldList = { ...state?.getListUsersData?.data };

      return {
        ...state,
        getListUsersData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_LIST_USERS: {
      let oldList = { ...state?.getListUsersData?.data };
      if (oldList?.users?.length > 0) {
        oldList["users"] = oldList?.users?.concat(action?.payload?.data?.users);
      } else {
        oldList = action.payload?.data;
      }

      return {
        ...state,
        getListUsersData: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_LIST_USERS: {
      return {
        ...state,
        getListUsersData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_ADD_USERS_LIST: {
      let oldList = { ...state?.getAddUsersList?.data };

      return {
        ...state,
        getAddUsersList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_ADD_USERS_LIST: {
      let oldList = { ...state?.getAddUsersList?.data };
      if (oldList?.data?.length > 0) {
        oldList["data"] = [...oldList?.data, ...action?.payload?.data];
      } else {
        oldList = action.payload;
      }

      return {
        ...state,
        getAddUsersList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_ADD_USERS_LIST: {
      return {
        ...state,
        getAddUsersList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_ADD_USERS_TO_LIST: {
      return {
        ...state,
        addUsersToListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_USERS_TO_LIST: {
      let oldData = { ...state?.getListData?.data };
      let oldList = { ...state?.getListUsersData?.data };
      let dialogConfigs = { ...root?.config?.listDialogConfigs };
      if (action?.listData?.is_remove) {
        oldData["data"] = oldData?.data?.map((item) => {
          if (item?.id == action?.listData?.list_id) {
            return {
              ...item,
              list_user_ids: item?.list_user_ids?.filter(
                (item1) => !action?.listData?.user_ids?.includes(item1)
              ),
              user_counts: item?.user_counts - 1,
            };
          } else {
            return item;
          }
        });
        if (dialogConfigs?.data?.activeListId == action?.listData?.list_id) {
          oldList["users"] = oldList?.users?.filter(
            (item) => !action?.listData?.user_ids?.includes(item?.id)
          );
          oldList["user_count"] = oldList?.user_count - 1;
        }
      } else {
        oldData["data"] = oldData?.data?.map((item) => {
          if (item?.id == action?.listData?.list_id) {
            return {
              ...item,
              list_user_ids: [
                ...(item?.list_user_ids || []),
                ...action?.listData?.user_ids,
              ],
              user_counts: (item?.user_counts || 0) + 1,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        addUsersToListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
        getListData: {
          ...state?.getListData,
          data: oldData,
        },
      };
    }
    case ERROR_ADD_USERS_TO_LIST: {
      return {
        ...state,
        addUsersToListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SUCCESS_SUBSCRIPTION_ACTION: {
      let oldList = { ...state?.getListUsersData?.data };

      if (oldList?.users?.length) {
        oldList["users"] = oldList?.users?.map((item) => {
          if (item?.id == action?.payload?.user?.id) {
            return {
              ...item,
              ...action?.payload?.user,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        refreshCounts: true,
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
      };
    }

    case SUCCESS_BLOCK_USER: {
      let oldList = { ...state?.getListUsersData?.data };

      if (oldList?.users?.length) {
        oldList["users"] = oldList?.users?.filter(
          (item) => item?.id != action?.user_id
        );
        oldList["user_count"] = oldList?.user_count - 1;
      }

      return {
        ...state,
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
      };
    }

    case SUCCESS_UNBLOCK_USER: {
      let oldList = { ...state?.getListUsersData?.data };

      if (oldList?.users?.length) {
        oldList["users"] = oldList?.users?.filter(
          (item) => item?.id != action?.user_id
        );
        oldList["user_count"] = oldList?.user_count - 1;
      }

      return {
        ...state,
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
      };
    }

    // Socket Events

    case SOCKET_LOGIN_USER: {
      const { user_id, show_activity_status = true } = action.payload;
      let oldList = { ...state?.getListUsersData?.data };

      oldList["users"] = oldList?.users?.map((item) => {
        if (item?.id == user_id) {
          return {
            ...item,
            is_active: 1,
            show_activity_status: show_activity_status,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
      };
    }

    case SOCKET_LOGOUT_USER: {
      const { user_id } = action.payload;
      let oldList = { ...state?.getListUsersData?.data };

      oldList["users"] = oldList?.users?.map((item) => {
        if (item?.id == user_id) {
          return {
            ...item,
            is_active: 0,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        getListUsersData: {
          ...state.getListUsersData,
          data: oldList,
        },
      };
    }

    default: {
      return state || initialState;
    }
  }
};
