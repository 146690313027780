import { generateDynamicEndpoint } from "utils/generalUtils";
import API from "../service";
import {
  CREATE_NEW_STREAM_ENDPOINT,
  GET_STREAM_BY_ID_ENDPOINT,
  JOIN_LIVE_STREAM_ENDPOINT,
  LEAVE_LIVE_STREAM_ENDPOINT,
  PLAY_STREAM_VIDEO_ENDPOINT,
  PURCHASE_STREAM_ENDPOINT,
  SHARE_STREAM_AS_POST_ENDPOINT,
  STOP_STREAM_ENDPOINT,
  UPDATE_TIP_GOAL_ENDPOINT,
} from "./endpoints";

export const createNewStreamAPI = (payload) => {
  return API.post(CREATE_NEW_STREAM_ENDPOINT, payload);
};

export const stopStreamAPI = (payload) => {
  return API.post(STOP_STREAM_ENDPOINT, payload);
};

export const getStreamByIdAPI = (stream_id) => {
  return API.get(
    generateDynamicEndpoint(GET_STREAM_BY_ID_ENDPOINT, { stream_id })
  );
};

export const joinLiveStreamAPI = (stream_id) => {
  return API.get(
    generateDynamicEndpoint(JOIN_LIVE_STREAM_ENDPOINT, { stream_id })
  );
};

export const leaveLiveStreamAPI = (stream_id) => {
  return API.get(
    generateDynamicEndpoint(LEAVE_LIVE_STREAM_ENDPOINT, { stream_id })
  );
};

export const shareStreamAsPosAPI = (stream_id, payload) => {
  return API.post(
    generateDynamicEndpoint(SHARE_STREAM_AS_POST_ENDPOINT, { stream_id }),
    payload
  );
};

export const playStreamVideoAPI = (payload) => {
  return API.post(PLAY_STREAM_VIDEO_ENDPOINT, payload);
};

export const purchaseStreamAPI = (payload) => {
  return API.post(PURCHASE_STREAM_ENDPOINT, payload);
};

export const updateTipGoalAPI = (stream_id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_TIP_GOAL_ENDPOINT, { stream_id }),
    payload
  );
};
