import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_DISMISS_USER_HINTS,
  ERROR_GET_USER_HINTS,
  START_DISMISS_USER_HINTS,
  START_GET_USER_HINTS,
  SUCCESS_DISMISS_USER_HINTS,
  SUCCESS_GET_USER_HINTS,
} from "Actions/CommonAction";
import { dismissUserHintsAPI, getUserHintsAPI } from "Api/CommonApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* getUserHintsWorker({ payload }) {
  try {
    const res = yield call(getUserHintsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_USER_HINTS,
        payload: { ...res, ...payload },
      });
    } else {
      yield put({
        type: ERROR_GET_USER_HINTS,
        payload: { ...res, ...payload },
      });
    }
  } catch (error) {}
}

function* dismissUserHintsWorker({ payload }) {
  try {
    const res = yield call(dismissUserHintsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DISMISS_USER_HINTS,
        payload: { ...res, ...payload },
      });
    } else {
      yield put({
        type: ERROR_DISMISS_USER_HINTS,
        payload: { ...res, ...payload },
      });
    }
  } catch (error) {}
}

export function* CommonWatcher() {
  yield takeEvery(START_GET_USER_HINTS, getUserHintsWorker);
  yield takeEvery(START_DISMISS_USER_HINTS, dismissUserHintsWorker);
}
