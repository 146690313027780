import * as Mui from "@mui/material";
import * as MuiStyles from "@mui/styles";
import clsx from "clsx";
import PrimaryIcon from "Components/Common/PrimaryIcon";
export { makeStyles, useTheme } from "@mui/styles";
export * from "@mui/material";
export { TimelineDot } from "@mui/lab";

const useStyle = MuiStyles.makeStyles((theme) => ({
  yhPrimaryTextfield: {
    "& .Mui-focused": {
      "&>fieldset": {
        borderWidth: "1px !important",
      },
    },
    width: "100% !important",
    "&>div": {
      width: "100% !important",
      borderRadius: "5px",
      color: theme.palette.secondary.main,
      "&>fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  noBorder: {
    "&>div": {
      "&>fieldset": {
        border: "none",
      },
    },
  },
  tabChip: {
    backgroundColor: `${theme.palette.background.tabChip} !important`,
    fontSize: "14px !important",
    borderRadius: "5px !important",
    padding: "8px 0px !important",
    color: `${theme.palette.secondary.main} !important`,
    marginRight: "5px",
    "& span": {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    "& svg": {
      fill: `${theme.palette.secondary.main} !important`,
    },
  },
  tabChipActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.white.main} !important`,
    fontWeight: 500,
  },
  yhMentionText: {
    color: `${theme.palette.primary.main} !important`,
    cursor: "pointer",
    fontWeight: "500",
  },
  tabChipRounded: {
    borderRadius: "20px !important",
  },
}));

const Typography = (props) => {
  const { color = "black", children, ...other } = props;
  const theme = MuiStyles.useTheme();

  let colors = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    black: theme.palette.black.main,
    white: theme.palette.white.main,
    error: theme.palette.error.main,
  };

  Object.keys(theme?.palette?.grey || {}).map((key) => {
    colors["grey" + key] = theme?.palette?.grey[key];
  });

  return (
    <>
      <Mui.Typography style={{ color: colors[color] }} {...other}>
        {children}
      </Mui.Typography>
    </>
  );
};

const TextField = (props) => {
  const {
    className,
    startIcon = "",
    endIcon = "",
    noBorder,
    handleEndIcon,
    ...other
  } = props;

  const classes = useStyle();

  return (
    <>
      <Mui.TextField
        variant="outlined"
        className={clsx(
          className,
          classes.yhPrimaryTextfield,
          noBorder && classes.noBorder
        )}
        size="small"
        InputProps={{
          ...(endIcon && {
            endAdornment: (
              <Mui.InputAdornment position="end" style={{ cursor: "pointer" }}>
                <Mui.IconButton
                  onClick={handleEndIcon && handleEndIcon}
                  color="primary"
                >
                  <PrimaryIcon icon={endIcon} color="secondary" />
                </Mui.IconButton>
              </Mui.InputAdornment>
            ),
          }),
          ...(startIcon && {
            endAdornment: (
              <Mui.InputAdornment position="end">
                <PrimaryIcon icon={startIcon} color="secondary" />
              </Mui.InputAdornment>
            ),
          }),
          disableUnderline: true,
        }}
        {...other}
      />
    </>
  );
};

const MentionText = (props) => {
  const { children, variant = "body1", ...other } = props;

  const classes = useStyle();
  return (
    <>
      <span className={classes.yhMentionText} variant={variant} {...other}>
        {children}
      </span>
    </>
  );
};

const TabChip = ({ className, selected, rounded, ...props }) => {
  const classes = useStyle();

  return (
    <Mui.Chip
      className={clsx(
        classes.tabChip,
        { [classes.tabChipActive]: Boolean(selected) },
        rounded && classes.tabChipRounded,
        className
      )}
      {...props}
    />
  );
};

export { Typography, TextField, TabChip, MentionText };
