export const RESET_COMMON_REDUCER = "RESET_COMMON_REDUCER";

// Hints
export const START_GET_USER_HINTS = "START_GET_USER_HINTS";
export const SUCCESS_GET_USER_HINTS = "SUCCESS_GET_USER_HINTS";
export const ERROR_GET_USER_HINTS = "ERROR_GET_USER_HINTS";

export const START_DISMISS_USER_HINTS = "START_DISMISS_USER_HINTS";
export const SUCCESS_DISMISS_USER_HINTS = "SUCCESS_DISMISS_USER_HINTS";
export const ERROR_DISMISS_USER_HINTS = "ERROR_DISMISS_USER_HINTS";

// Socket
export const SOCKET_USER_HINTS_UPDATED = "SOCKET_USER_HINTS_UPDATED";
export const SOCKET_USER_HINTS_DELETED = "SOCKET_USER_HINTS_DELETED";
