import {
  START_GET_COLLAB_POSTS,
  SUCCESS_GET_COLLAB_POSTS,
  ERROR_GET_COLLAB_POSTS,
  START_COLLAB_POSTS_ACTION,
  SUCCESS_COLLAB_POSTS_ACTION,
  ERROR_COLLAB_POSTS_ACTION,
} from "Actions/CollabPosts";

import { getCollabPostsAPI, collabPostsActionsAPI } from "Api/CollabPostsApi";

import { call, put, takeEvery } from "redux-saga/effects";

function* getCollabPostsWorker({ payload }) {
  try {
    const res = yield call(getCollabPostsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_COLLAB_POSTS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_COLLAB_POSTS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* actionCollabPostsWorker({ payload }) {
  try {
    const res = yield call(collabPostsActionsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_COLLAB_POSTS_ACTION,
        payload: res,
        post_id: payload.post_id,
        actionStatus: payload.status,
      });
    } else {
      yield put({
        type: ERROR_COLLAB_POSTS_ACTION,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* CollabPostsWatcher() {
  yield takeEvery(START_GET_COLLAB_POSTS, getCollabPostsWorker);
  yield takeEvery(START_COLLAB_POSTS_ACTION, actionCollabPostsWorker);
}
