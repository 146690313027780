import { generateDynamicEndpoint } from "utils/generalUtils";
import API from "../service";
import {
  GET_QUEUE_COUNTS_ENDPOINT,
  GET_QUEUE_LIST_ENDPOINT,
  GET_SAVED_FOR_LATER_POSTS_ENDPOINT,
  POST_SAVED_FOR_LATER_ENDPOINT,
  TAKE_SCHEDULE_ACTION_ENDPOINT,
} from "./endpoints";

export const getQueueCountsAPI = (payload) => {
  return API.get(GET_QUEUE_COUNTS_ENDPOINT, payload);
};

export const getQueueListAPI = (payload) => {
  return API.get(GET_QUEUE_LIST_ENDPOINT, payload);
};

export const takeScheduleActionAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(TAKE_SCHEDULE_ACTION_ENDPOINT, { action_id: id }),
    payload
  );
};

export const getSavedForLaterConfigAPI = () => {
  return API.get(POST_SAVED_FOR_LATER_ENDPOINT);
};

export const updateSavedForLaterConfigAPI = (payload) => {
  return API.put(POST_SAVED_FOR_LATER_ENDPOINT, payload);
};

export const getSavedForLaterPostsAPI = (payload) => {
  return API.get(GET_SAVED_FOR_LATER_POSTS_ENDPOINT);
};
