export const RESET_SETTING_STATE = "RESET_SETTING_STATE";

export const START_UPDATE_CHAT_SETTINGS = "START_UPDATE_CHAT_SETTINGS";
export const SUCCESS_UPDATE_CHAT_SETTINGS = "SUCCESS_UPDATE_CHAT_SETTINGS";
export const ERROR_UPDATE_CHAT_SETTINGS = "ERROR_UPDATE_CHAT_SETTINGS";

export const START_DELETE_WELCOME_MSG = "START_DELETE_WELCOME_MSG";
export const SUCCESS_DELETE_WELCOME_MSG = "SUCCESS_DELETE_WELCOME_MSG";
export const ERROR_DELETE_WELCOME_MSG = "ERROR_DELETE_WELCOME_MSG";

export const START_GET_WELCOME_MSG = "START_GET_WELCOME_MSG";
export const SUCCESS_GET_WELCOME_MSG = "SUCCESS_GET_WELCOME_MSG";
export const ERROR_GET_WELCOME_MSG = "ERROR_GET_WELCOME_MSG";

export const START_CREATE_CHAT_MSG = "START_CREATE_CHAT_MSG";
export const SUCCESS_CREATE_CHAT_MSG = "SUCCESS_CREATE_CHAT_MSG";
export const ERROR_CREATE_CHAT_MSG = "ERROR_CREATE_CHAT_MSG";

export const START_UPDATE_WELCOME_MSG = "START_UPDATE_WELCOME_MSG";
export const SUCCESS_UPDATE_WELCOME_MSG = "SUCCESS_UPDATE_WELCOME_MSG";
export const ERROR_UPDATE_WELCOME_MSG = "ERROR_UPDATE_WELCOME_MSG";

export const START_CREATE_TRACKING_LINK = "START_CREATE_TRACKING_LINK";
export const SUCCESS_CREATE_TRACKING_LINK = "SUCCESS_CREATE_TRACKING_LINK";
export const ERROR_CREATE_TRACKING_LINK = "ERROR_CREATE_TRACKING_LINK";

export const START_GET_TRACKING_LINKS = "START_GET_TRACKING_LINKS";
export const SUCCESS_GET_TRACKING_LINKS = "SUCCESS_GET_TRACKING_LINKS";
export const ERROR_GET_TRACKING_LINKS = "ERROR_GET_TRACKING_LINKS";

export const START_DELETE_TRACKING_LINK = "START_DELETE_TRACKING_LINK";
export const SUCCESS_DELETE_TRACKING_LINK = "SUCCESS_DELETE_TRACKING_LINK";
export const ERROR_DELETE_TRACKING_LINK = "ERROR_DELETE_TRACKING_LINK";

export const START_CLICK_TRACK_LINK = "START_CLICK_TRACK_LINK";
export const SUCCESS_CLICK_TRACK_LINK = "SUCCESS_CLICK_TRACK_LINK";
export const ERROR_CLICK_TRACK_LINK = "ERROR_CLICK_TRACK_LINK";

export const START_GET_TRACKING_LINK_SUBSCRIBERS =
  "START_GET_TRACKING_LINK_SUBSCRIBERS";
export const SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS =
  "SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS";
export const ERROR_GET_TRACKING_LINK_SUBSCRIBERS =
  "ERROR_GET_TRACKING_LINK_SUBSCRIBERS";

export const START_SEND_SECURITY_CODE = "START_SEND_SECURITY_CODE";
export const SUCCESS_SEND_SECURITY_CODE = "SUCCESS_SEND_SECURITY_CODE";
export const ERROR_SEND_SECURITY_CODE = "ERROR_SEND_SECURITY_CODE";

export const START_SEND_OTP_CODE = "START_SEND_OTP_CODE";
export const SUCCESS_SEND_OTP_CODE = "SUCCESS_SEND_OTP_CODE";
export const ERROR_SEND_OTP_CODE = "ERROR_SEND_OTP_CODE";

export const START_VERIFY_SECURITY_CODE = "START_VERIFY_SECURITY_CODE";
export const SUCCESS_VERIFY_SECURITY_CODE = "SUCCESS_VERIFY_SECURITY_CODE";
export const ERROR_VERIFY_SECURITY_CODE = "ERROR_VERIFY_SECURITY_CODE";

export const START_DISCONNECT_PHONE_NUMBER = "START_DISCONNECT_PHONE_NUMBER";
export const SUCCESS_DISCONNECT_PHONE_NUMBER =
  "SUCCESS_DISCONNECT_PHONE_NUMBER";
export const ERROR_DISCONNECT_PHONE_NUMBER = "ERROR_DISCONNECT_PHONE_NUMBER";

export const START_TOGGLE_TWO_STEP_AUTH = "START_TOGGLE_TWO_STEP_AUTH";
export const SUCCESS_TOGGLE_TWO_STEP_AUTH = "SUCCESS_TOGGLE_TWO_STEP_AUTH";
export const ERROR_TOGGLE_TWO_STEP_AUTH = "ERROR_TOGGLE_TWO_STEP_AUTH";

export const START_GET_AUTH_CODES = "START_GET_AUTH_CODES";
export const SUCCESS_GET_AUTH_CODES = "SUCCESS_GET_AUTH_CODES";
export const ERROR_GET_AUTH_CODES = "ERROR_GET_AUTH_CODES";

export const START_GET_LOGIN_SESSIONS = "START_GET_LOGIN_SESSIONS";
export const SUCCESS_GET_LOGIN_SESSIONS = "SUCCESS_GET_LOGIN_SESSIONS";
export const ERROR_GET_LOGIN_SESSIONS = "ERROR_GET_LOGIN_SESSIONS";

export const START_CLOSE_LOGIN_SESSIONS = "START_CLOSE_LOGIN_SESSIONS";
export const SUCCESS_CLOSE_LOGIN_SESSIONS = "SUCCESS_CLOSE_LOGIN_SESSIONS";
export const ERROR_CLOSE_LOGIN_SESSIONS = "ERROR_CLOSE_LOGIN_SESSIONS";

export const START_GET_USER_METADATA = "START_GET_USER_METADATA";
export const SUCCESS_GET_USER_METADATA = "SUCCESS_GET_USER_METADATA";
export const ERROR_GET_USER_METADATA = "ERROR_GET_USER_METADATA";

export const START_UPDATE_USER_METADATA = "START_UPDATE_USER_METADATA";
export const SUCCESS_UPDATE_USER_METADATA = "SUCCESS_UPDATE_USER_METADATA";
export const ERROR_UPDATE_USER_METADATA = "ERROR_UPDATE_USER_METADATA";

// Socket
export const SOCKET_WELCOME_MSG_VIDEO_UPLOAD =
  "SOCKET_WELCOME_MSG_VIDEO_UPLOAD";
