export const START_GET_MEMBERS = "START_GET_MEMBERS";
export const SUCCESS_GET_MEMBERS = "SUCCESS_GET_MEMBERS";
export const ERROR_GET_MEMBERS = "ERROR_GET_MEMBERS";

export const START_INVITE_MEMBERS = "START_INVITE_MEMBERS";
export const SUCCESS_INVITE_MEMBERS = "SUCCESS_INVITE_MEMBERS";
export const ERROR_INVITE_MEMBERS = "ERROR_INVITE_MEMBERS";

export const START_SUBMIT_INVITE_RESPONSE = "START_SUBMIT_INVITE_RESPONSE";
export const SUCCESS_SUBMIT_INVITE_RESPONSE = "SUCCESS_SUBMIT_INVITE_RESPONSE";
export const ERROR_SUBMIT_INVITE_RESPONSE = "ERROR_SUBMIT_INVITE_RESPONSE";

export const START_REMOVE_MEMBERS = "START_REMOVE_MEMBERS";
export const SUCCESS_REMOVE_MEMBERS = "SUCCESS_REMOVE_MEMBERS";
export const ERROR_REMOVE_MEMBERS = "ERROR_REMOVE_MEMBERS";

export const START_EDIT_MEMBER = "START_EDIT_MEMBER";
export const SUCCESS_EDIT_MEMBER = "SUCCESS_EDIT_MEMBER";
export const ERROR_EDIT_MEMBER = "ERROR_EDIT_MEMBER";