export const RESET_CHAT_ACTION = "RESET_CHAT_ACTION";

export const START_GET_CHAT_LIST = "START_GET_CHAT_LIST";
export const SUCCESS_GET_CHAT_LIST = "SUCCESS_GET_CHAT_LIST";
export const SUCCESS_GET_CHAT_LIST_CACHED = "SUCCESS_GET_CHAT_LIST_CACHED";
export const ERROR_GET_CHAT_LIST = "ERROR_GET_CHAT_LIST";

export const START_GET_NEW_MESSAGE_LIST = "START_GET_NEW_MESSAGE_LIST";
export const SUCCESS_GET_NEW_MESSAGE_LIST = "SUCCESS_GET_NEW_MESSAGE_LIST";
export const ERROR_GET_NEW_MESSAGE_LIST = "ERROR_GET_NEW_MESSAGE_LIST";

export const START_CREATE_NEW_CHAT = "START_CREATE_NEW_CHAT";
export const SUCCESS_CREATE_NEW_CHAT = "SUCCESS_CREATE_NEW_CHAT";
export const ERROR_CREATE_NEW_CHAT = "ERROR_CREATE_NEW_CHAT";

export const START_GET_CHAT_CONVERSATION = "START_GET_CHAT_CONVERSATION";
export const SUCCESS_GET_CHAT_CONVERSATION_CATCHED = "SUCCESS_GET_CHAT_CONVERSATION_CATCHED";
export const SUCCESS_GET_CHAT_CONVERSATION = "SUCCESS_GET_CHAT_CONVERSATION";
export const ERROR_GET_CHAT_CONVERSATION = "ERROR_GET_CHAT_CONVERSATION";

export const START_SEND_MESSAGE = "START_SEND_MESSAGE";
export const SUCCESS_SEND_MESSAGE = "SUCCESS_SEND_MESSAGE";
export const ERROR_SEND_MESSAGE = "ERROR_SEND_MESSAGE";

export const START_READ_MESSAGE = "START_READ_MESSAGE";
export const SUCCESS_READ_MESSAGE = "SUCCESS_READ_MESSAGE";
export const ERROR_READ_MESSAGE = "ERROR_READ_MESSAGE";

export const START_DELETE_CHAT = "START_DELETE_CHAT";
export const SUCCESS_DELETE_CHAT = "SUCCESS_DELETE_CHAT";
export const ERROR_DELETE_CHAT = "ERROR_DELETE_CHAT";

export const START_DELETE_CHAT_MESSAGES = "START_DELETE_CHAT_MESSAGES";
export const SUCCESS_DELETE_CHAT_MESSAGES = "SUCCESS_DELETE_CHAT_MESSAGES";
export const ERROR_DELETE_CHAT_MESSAGES = "ERROR_DELETE_CHAT_MESSAGES";

export const START_PURCHASE_MESSAGE = "START_PURCHASE_MESSAGE";
export const SUCCESS_PURCHASE_MESSAGE = "SUCCESS_PURCHASE_MESSAGE";
export const ERROR_PURCHASE_MESSAGE = "ERROR_PURCHASE_MESSAGE";

export const SET_MASS_MESSAGE_NOTIFICATIONS = "SET_MASS_MESSAGE_NOTIFICATIONS";

export const START_GET_SINGLE_MASS_MESSAGE = "START_GET_SINGLE_MASS_MESSAGE";
export const SUCCESS_GET_SINGLE_MASS_MESSAGE =
  "SUCCESS_GET_SINGLE_MASS_MESSAGE";
export const ERROR_GET_SINGLE_MASS_MESSAGE = "ERROR_GET_SINGLE_MASS_MESSAGE";

export const START_UPDATE_MASS_MESSAGE = "START_UPDATE_MASS_MESSAGE";
export const SUCCESS_UPDATE_MASS_MESSAGE = "SUCCESS_UPDATE_MASS_MESSAGE";
export const ERROR_UPDATE_MASS_MESSAGE = "ERROR_UPDATE_MASS_MESSAGE";

// Socket Actions
export const SOCKET_ADD_NEW_MESSAGE = "SOCKET_ADD_NEW_MESSAGE";
export const SOCKET_CHAT_VIDEO_UPLOAD = "SOCKET_CHAT_VIDEO_UPLOAD";
export const SOCKET_MESSAGE_PURCHASED = "SOCKET_MESSAGE_PURCHASED";
export const SOCKET_USER_TYPING = "SOCKET_USER_TYPING";
export const SOCKET_MASS_MESSAGE_QUEUE_UPDATE =
  "SOCKET_MASS_MESSAGE_QUEUE_UPDATE";
