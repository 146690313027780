import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  CLICK_TRACK_LINK_ENDPOINT,
  CREATE_CHAT_MESSAGE_ENDPOINT,
  CREATE_TRACKING_LINK_ENDPOINT,
  CREATE_WELCOME_MSG_ENDPOINT,
  DELETE_TRACKING_LINK_ENDPOINT,
  DELETE_WELCOME_MSG_ENDPOINT,
  DISCONNECT_PHONE_ENDPOINT,
  GET_AUTH_CODES_ENDPOINT,
  GET_TRACKING_LINK_ENDPOINT,
  GET_TRACKING_LINK_SUBSCRIBERS_ENDPOINT,
  GET_WELCOME_MSG_ENDPOINT,
  LOGIN_SESSION_ENDPOINT,
  SEND_OTP_CODE_ENDPOINT,
  SEND_SECURITY_CODE_ENDPOINT,
  TOGGLE_TWO_STEP_AUTH_ENDPOINT,
  UPDATE_CHAT_SETTING_ENDPOINT,
  UPDATE_WELCOME_MSG_ENDPOINT,
  USER_METADATA_ENDPOINT,
  VERIFY_SECURITY_CODE_ENDPOINT,
} from "./endpoints";

export const updateChatSettingAPI = (payload) => {
  return API.post(UPDATE_CHAT_SETTING_ENDPOINT, payload);
};

export const deleteWelcomeMsgAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_WELCOME_MSG_ENDPOINT, { id })
  );
};

export const getWelcomeMsgAPI = () => {
  return API.get(GET_WELCOME_MSG_ENDPOINT);
};

export const createWelcomeMsgAPI = (payload) => {
  return API.post(CREATE_CHAT_MESSAGE_ENDPOINT, payload);
};

export const updateWelcomeMsgAPI = (payload) => {
  return API.put(UPDATE_WELCOME_MSG_ENDPOINT, payload);
};

export const createTrackingLinkAPI = (payload) => {
  return API.post(CREATE_TRACKING_LINK_ENDPOINT, payload);
};

export const deleteTrackingLinkAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_TRACKING_LINK_ENDPOINT, { id })
  );
};

export const getTrackingLinksAPI = (payload) => {
  return API.get(GET_TRACKING_LINK_ENDPOINT, payload);
};

export const clickTrackLinkAPI = (payload) => {
  return API.post(CLICK_TRACK_LINK_ENDPOINT, payload);
};

export const getTrackingLinkSubscribersAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_TRACKING_LINK_SUBSCRIBERS_ENDPOINT, { id }),
    payload
  );
};

export const sendSecurityCodeAPI = (payload) => {
  return API.post(SEND_SECURITY_CODE_ENDPOINT, payload);
};

export const sendOtpCodeAPI = (payload) => {
  return API.post(
    SEND_OTP_CODE_ENDPOINT,
    payload,
    localStorage.getItem("authToken")
  );
};

export const verifySecurityCodeAPI = (payload) => {
  return API.post(VERIFY_SECURITY_CODE_ENDPOINT, payload);
};

export const disconnectPhoneAPI = (payload) => {
  return API.deleteM(DISCONNECT_PHONE_ENDPOINT, payload);
};

export const toggleTwoStepAuthAPI = (payload) => {
  return API.post(TOGGLE_TWO_STEP_AUTH_ENDPOINT, payload);
};

export const getAuthCodesAPI = (payload) => {
  return API.get(GET_AUTH_CODES_ENDPOINT, payload);
};

export const getLoginSessionAPI = (payload) => {
  return API.post(LOGIN_SESSION_ENDPOINT, payload);
};

export const closeLoginSessionAPI = () => {
  return API.deleteM(LOGIN_SESSION_ENDPOINT);
};

export const getUserMetadataAPI = () => {
  return API.get(USER_METADATA_ENDPOINT);
};

export const updateUserMetadataAPI = (payload) => {
  return API.put(USER_METADATA_ENDPOINT, payload);
};
