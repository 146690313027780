import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  ADD_USERS_TO_LIST_ENDPOINT,
  CLEAR_LIST_ENDPOINT,
  CREATE_LIST_ENDPOINT,
  DELETE_LIST_ENDPOINT,
  GET_ADD_USER_ENDPOINT,
  GET_LIST_ENDPOINT,
  GET_LIST_USERS_ENDPOINT,
  GET_SINGLE_LIST_ENDPOINT,
  UPDATE_LIST_ENDPOINT,
} from "./endpoints";

export const createListAPI = (payload) => {
  return API.post(CREATE_LIST_ENDPOINT, payload);
};

export const getListAPI = (payload) => {
  return API.get(GET_LIST_ENDPOINT, payload);
};

export const updateListAPI = (id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_LIST_ENDPOINT, { id }),
    payload
  );
};

export const deleteListAPI = (id) => {
  return API.deleteM(generateDynamicEndpoint(DELETE_LIST_ENDPOINT, { id }));
};

export const clearListAPI = (id) => {
  return API.post(generateDynamicEndpoint(CLEAR_LIST_ENDPOINT, { id }));
};

export const getSingleListAPI = (id) => {
  return API.get(generateDynamicEndpoint(GET_SINGLE_LIST_ENDPOINT, { id }));
};

export const getListUsersAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_LIST_USERS_ENDPOINT, { id }),
    payload
  );
};

export const getAddUsersListAPI = (payload) => {
  return API.get(GET_ADD_USER_ENDPOINT, payload);
};

export const addUsersToListAPI = (payload) => {
  return API.post(ADD_USERS_TO_LIST_ENDPOINT, payload);
};
