import { SOCKET_IMAGE_MEDIA_UPLOADED } from "Actions/PostAction";
import {
  START_UPDATE_CHAT_SETTINGS,
  SUCCESS_UPDATE_CHAT_SETTINGS,
  ERROR_UPDATE_CHAT_SETTINGS,
  RESET_SETTING_STATE,
  START_DELETE_WELCOME_MSG,
  SUCCESS_DELETE_WELCOME_MSG,
  ERROR_DELETE_WELCOME_MSG,
  START_GET_WELCOME_MSG,
  SUCCESS_GET_WELCOME_MSG,
  ERROR_GET_WELCOME_MSG,
  START_CREATE_CHAT_MSG,
  SUCCESS_CREATE_CHAT_MSG,
  ERROR_CREATE_CHAT_MSG,
  START_UPDATE_WELCOME_MSG,
  SUCCESS_UPDATE_WELCOME_MSG,
  ERROR_UPDATE_WELCOME_MSG,
  SOCKET_WELCOME_MSG_VIDEO_UPLOAD,
  START_CREATE_TRACKING_LINK,
  SUCCESS_CREATE_TRACKING_LINK,
  ERROR_CREATE_TRACKING_LINK,
  START_GET_TRACKING_LINKS,
  SUCCESS_GET_TRACKING_LINKS,
  ERROR_GET_TRACKING_LINKS,
  START_DELETE_TRACKING_LINK,
  SUCCESS_DELETE_TRACKING_LINK,
  ERROR_DELETE_TRACKING_LINK,
  START_CLICK_TRACK_LINK,
  SUCCESS_CLICK_TRACK_LINK,
  ERROR_CLICK_TRACK_LINK,
  START_GET_TRACKING_LINK_SUBSCRIBERS,
  SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS,
  ERROR_GET_TRACKING_LINK_SUBSCRIBERS,
  START_SEND_SECURITY_CODE,
  SUCCESS_SEND_SECURITY_CODE,
  ERROR_SEND_SECURITY_CODE,
  START_VERIFY_SECURITY_CODE,
  SUCCESS_VERIFY_SECURITY_CODE,
  ERROR_VERIFY_SECURITY_CODE,
  START_DISCONNECT_PHONE_NUMBER,
  SUCCESS_DISCONNECT_PHONE_NUMBER,
  ERROR_DISCONNECT_PHONE_NUMBER,
  START_TOGGLE_TWO_STEP_AUTH,
  SUCCESS_TOGGLE_TWO_STEP_AUTH,
  ERROR_TOGGLE_TWO_STEP_AUTH,
  START_GET_AUTH_CODES,
  SUCCESS_GET_AUTH_CODES,
  ERROR_GET_AUTH_CODES,
  START_GET_LOGIN_SESSIONS,
  SUCCESS_GET_LOGIN_SESSIONS,
  ERROR_GET_LOGIN_SESSIONS,
  START_CLOSE_LOGIN_SESSIONS,
  SUCCESS_CLOSE_LOGIN_SESSIONS,
  ERROR_CLOSE_LOGIN_SESSIONS,
  START_SEND_OTP_CODE,
  SUCCESS_SEND_OTP_CODE,
  ERROR_SEND_OTP_CODE,
  START_GET_USER_METADATA,
  SUCCESS_GET_USER_METADATA,
  ERROR_GET_USER_METADATA,
  START_UPDATE_USER_METADATA,
  SUCCESS_UPDATE_USER_METADATA,
  ERROR_UPDATE_USER_METADATA,
} from "Actions/SettingsAction";

const initialState = {
  userMetadata: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateUserMetadata: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateChatSettingData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteWelcomeMsgData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getWelcomeMsgData: {
    isLoading: false,
    data: {},
    error: "",
  },
  createChatMsgData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateWelcomeMsgData: {
    isLoading: false,
    data: {},
    error: "",
  },
  createTrackingLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteTrackingLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getTrackingLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  clickTrackLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  trackingLinkSubscribers: {
    isLoading: false,
    data: {},
    error: "",
  },
  securityCodeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  verifyCodeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  disconnectPhoneData: {
    isLoading: false,
    data: {},
    error: "",
  },
  toggleTwoStepAuthData: {
    isLoading: false,
    data: {},
    error: "",
  },
  authCodesData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getLoginSessionsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  closeLoginSessionsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  sendOtpCodeData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const settingsReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_SETTING_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_GET_USER_METADATA: {
      return {
        ...state,
        userMetadata: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_USER_METADATA: {
      return {
        ...state,
        userMetadata: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_USER_METADATA: {
      return {
        ...state,
        userMetadata: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_USER_METADATA: {
      return {
        ...state,
        updateUserMetadata: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_USER_METADATA: {
      let metaData = structuredClone(state?.userMetadata?.data?.data);
      metaData = { ...metaData, ...action.data };

      return {
        ...state,
        updateUserMetadata: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userMetadata: {
          ...state?.userMetadata,
          data: {
            ...state?.userMetadata?.data,
            data: metaData,
          },
        },
      };
    }
    case ERROR_UPDATE_USER_METADATA: {
      return {
        ...state,
        updateUserMetadata: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_CHAT_SETTINGS: {
      return {
        ...state,
        updateChatSettingData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_CHAT_SETTINGS: {
      return {
        ...state,
        updateChatSettingData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_UPDATE_CHAT_SETTINGS: {
      return {
        ...state,
        updateChatSettingData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_WELCOME_MSG: {
      return {
        ...state,
        deleteWelcomeMsgData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_WELCOME_MSG: {
      let oldMsg = { ...state?.getWelcomeMsgData };
      oldMsg["data"] = {};
      return {
        ...state,
        deleteWelcomeMsgData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getWelcomeMsgData: oldMsg,
      };
    }
    case ERROR_DELETE_WELCOME_MSG: {
      return {
        ...state,
        deleteWelcomeMsgData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_WELCOME_MSG: {
      return {
        ...state,
        getWelcomeMsgData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_WELCOME_MSG: {
      return {
        ...state,
        getWelcomeMsgData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_WELCOME_MSG: {
      return {
        ...state,
        getWelcomeMsgData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_CHAT_MSG: {
      return {
        ...state,
        createChatMsgData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_CHAT_MSG: {
      let oldData = { ...state?.getWelcomeMsgData?.data };
      oldData["welcomeMessage"] = action.payload.welcomeMessage;
      return {
        ...state,
        createChatMsgData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getWelcomeMsgData: {
          ...state.getWelcomeMsgData,
          data: oldData,
        },
      };
    }
    case ERROR_CREATE_CHAT_MSG: {
      return {
        ...state,
        createChatMsgData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_WELCOME_MSG: {
      return {
        ...state,
        updateWelcomeMsgData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_WELCOME_MSG: {
      let oldData = { ...state?.getWelcomeMsgData?.data };
      oldData["welcomeMessage"] = {
        ...oldData?.welcomeMessage,
        ...(action?.payload?.data || {}),
      };
      return {
        ...state,
        updateWelcomeMsgData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getWelcomeMsgData: {
          ...state.getWelcomeMsgData,
          data: oldData,
        },
      };
    }
    case ERROR_UPDATE_WELCOME_MSG: {
      return {
        ...state,
        updateWelcomeMsgData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CREATE_TRACKING_LINK: {
      return {
        ...state,
        createTrackingLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_CREATE_TRACKING_LINK: {
      let linkList = { ...state?.getTrackingLinkData?.data?.data };
      if (linkList?.rows?.length > 0) {
        linkList.rows = [action?.payload?.data, ...linkList?.rows];
      } else {
        linkList.rows = [action.payload.data];
      }
      return {
        ...state,
        createTrackingLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getTrackingLinkData: {
          ...state?.getTrackingLinkData,
          data: {
            ...state?.getTrackingLinkData?.data,
            data: linkList,
          },
        },
      };
    }

    case ERROR_CREATE_TRACKING_LINK: {
      return {
        ...state,
        createTrackingLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_TRACKING_LINKS: {
      return {
        ...state,
        getTrackingLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_TRACKING_LINKS: {
      return {
        ...state,
        getTrackingLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_TRACKING_LINKS: {
      return {
        ...state,
        getTrackingLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DELETE_TRACKING_LINK: {
      return {
        ...state,
        deleteTrackingLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_DELETE_TRACKING_LINK: {
      let linkList = { ...state?.getTrackingLinkData?.data?.data };
      linkList.rows = linkList?.rows?.filter(
        (item) => item?.id != action?.link_id
      );
      return {
        ...state,
        deleteTrackingLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getTrackingLinkData: {
          ...state?.getTrackingLinkData,
          data: {
            ...state?.getTrackingLinkData?.data,
            data: linkList,
          },
        },
      };
    }
    case ERROR_DELETE_TRACKING_LINK: {
      return {
        ...state,
        deleteTrackingLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CLICK_TRACK_LINK: {
      return {
        ...state,
        clickTrackLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CLICK_TRACK_LINK: {
      return {
        ...state,
        clickTrackLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CLICK_TRACK_LINK: {
      return {
        ...state,
        clickTrackLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_TRACKING_LINK_SUBSCRIBERS: {
      let oldList = { ...state?.trackingLinkSubscribers?.data };

      return {
        ...state,
        trackingLinkSubscribers: {
          isLoading: action.payload.offset > 0 ? false : true,
          data: action.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }

    case SUCCESS_GET_TRACKING_LINK_SUBSCRIBERS: {
      let oldList = { ...state?.trackingLinkSubscribers?.data };

      if (
        oldList?.data?.tracking_link_subscribers?.length > 0 &&
        action.offset > 0
      ) {
        oldList["data"]["tracking_link_subscribers"] = [
          ...oldList?.data?.tracking_link_subscribers,
          ...action?.payload?.data?.tracking_link_subscribers,
        ];
      } else {
        oldList = action.payload;
      }

      return {
        ...state,
        trackingLinkSubscribers: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }

    case ERROR_GET_TRACKING_LINK_SUBSCRIBERS: {
      return {
        ...state,
        trackingLinkSubscribers: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SEND_SECURITY_CODE: {
      return {
        ...state,
        securityCodeData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_SEND_SECURITY_CODE: {
      return {
        ...state,
        securityCodeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SEND_SECURITY_CODE: {
      return {
        ...state,
        securityCodeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SEND_OTP_CODE: {
      return {
        ...state,
        sendOtpCodeData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_SEND_OTP_CODE: {
      return {
        ...state,
        sendOtpCodeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SEND_OTP_CODE: {
      return {
        ...state,
        sendOtpCodeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_VERIFY_SECURITY_CODE: {
      return {
        ...state,
        verifyCodeData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_SECURITY_CODE: {
      return {
        ...state,
        verifyCodeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_VERIFY_SECURITY_CODE: {
      return {
        ...state,
        verifyCodeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DISCONNECT_PHONE_NUMBER: {
      return {
        ...state,
        disconnectPhoneData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DISCONNECT_PHONE_NUMBER: {
      return {
        ...state,
        disconnectPhoneData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DISCONNECT_PHONE_NUMBER: {
      return {
        ...state,
        disconnectPhoneData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_TOGGLE_TWO_STEP_AUTH: {
      return {
        ...state,
        toggleTwoStepAuthData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_TOGGLE_TWO_STEP_AUTH: {
      return {
        ...state,
        toggleTwoStepAuthData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_TOGGLE_TWO_STEP_AUTH: {
      return {
        ...state,
        toggleTwoStepAuthData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_AUTH_CODES: {
      return {
        ...state,
        authCodesData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_AUTH_CODES: {
      return {
        ...state,
        authCodesData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_AUTH_CODES: {
      return {
        ...state,
        authCodesData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_LOGIN_SESSIONS: {
      return {
        ...state,
        getLoginSessionsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_LOGIN_SESSIONS: {
      return {
        ...state,
        getLoginSessionsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_LOGIN_SESSIONS: {
      return {
        ...state,
        getLoginSessionsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CLOSE_LOGIN_SESSIONS: {
      return {
        ...state,
        closeLoginSessionsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CLOSE_LOGIN_SESSIONS: {
      let loginSessions = { ...state?.getLoginSessionsData?.data };

      if (loginSessions?.login_sessions?.length) {
        loginSessions.login_sessions = loginSessions?.login_sessions?.filter(
          (item) => item?.is_current
        );
      }

      return {
        ...state,
        closeLoginSessionsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getLoginSessionsData: {
          ...state?.getLoginSessionsData,
          data: loginSessions,
        },
      };
    }
    case ERROR_CLOSE_LOGIN_SESSIONS: {
      return {
        ...state,
        closeLoginSessionsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    // Socket Actions
    case SOCKET_WELCOME_MSG_VIDEO_UPLOAD: {
      const { sender, welcome_message_video } = action.payload;
      let oldData = { ...state?.getWelcomeMsgData?.data };
      oldData["welcomeMessage"] = {
        ...oldData?.welcomeMessage,
        welcome_message_videos:
          oldData?.welcomeMessage.welcome_message_videos?.map((video) => {
            if (video?.id == welcome_message_video?.id) {
              return {
                ...video,
                thumbnail: welcome_message_video?.thumbnail,
                video: welcome_message_video?.video,
                is_uploaded: true,
              };
            } else {
              return video;
            }
          }),
      };

      return {
        ...state,
        getWelcomeMsgData: {
          ...state.getWelcomeMsgData,
          data: oldData,
        },
      };
    }

    case SOCKET_IMAGE_MEDIA_UPLOADED: {
      const { category, data } = action.payload;

      let oldData = { ...state?.getWelcomeMsgData?.data };
      if (
        category == "welcome_message_image" &&
        Object.keys(oldData || {})?.length &&
        data?.id
      ) {
        oldData["welcomeMessage"] = {
          ...oldData?.welcomeMessage,
          welcome_message_images:
            oldData?.welcomeMessage.welcome_message_images?.map((image) => {
              if (image?.id == data?.id) {
                return {
                  ...image,
                  ...data,
                };
              } else {
                return image;
              }
            }),
        };
      }

      return {
        ...state,
        getWelcomeMsgData: {
          ...state?.getWelcomeMsgData,
          data: oldData,
        },
      };
    }

    default: {
      return state || initialState;
    }
  }
};
