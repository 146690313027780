import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_CHANGE_MEDIA_CATEGORY,
  ERROR_CHANGE_VAULT_LIST_ORDER,
  ERROR_CREATE_VAULT_LIST,
  ERROR_DELETE_VAULT_LIST,
  ERROR_DELETE_VAULT_MEDIA,
  ERROR_GET_INNER_VAULT,
  ERROR_GET_SINGLE_VAULT_LIST,
  ERROR_GET_VAULT_LIST,
  ERROR_GET_VAULT_MEDIA_BY_ID,
  ERROR_GET_VAULT_MEDIA_LIST,
  ERROR_UPDATE_VAULT_LIST,
  START_CHANGE_MEDIA_CATEGORY,
  START_CHANGE_VAULT_LIST_ORDER,
  START_CREATE_VAULT_LIST,
  START_DELETE_VAULT_LIST,
  START_DELETE_VAULT_MEDIA,
  START_GET_INNER_VAULT,
  START_GET_SINGLE_VAULT_LIST,
  START_GET_VAULT_LIST,
  START_GET_VAULT_MEDIA_BY_ID,
  START_GET_VAULT_MEDIA_LIST,
  START_UPDATE_VAULT_LIST,
  SUCCESS_CHANGE_MEDIA_CATEGORY,
  SUCCESS_CHANGE_VAULT_LIST_ORDER,
  SUCCESS_CREATE_VAULT_LIST,
  SUCCESS_DELETE_VAULT_LIST,
  SUCCESS_DELETE_VAULT_MEDIA,
  SUCCESS_GET_INNER_VAULT,
  SUCCESS_GET_SINGLE_VAULT_LIST,
  SUCCESS_GET_VAULT_LIST,
  SUCCESS_GET_VAULT_MEDIA_BY_ID,
  SUCCESS_GET_VAULT_MEDIA_LIST,
  SUCCESS_UPDATE_VAULT_LIST,
} from "Actions/VaultAction";
import {
  changeMediaCategoryAPI,
  changeVaultListOrderAPI,
  createVaultListAPI,
  deleteVaultListAPI,
  deleteVaultMediaAPI,
  getSingleVaultListAPI,
  getVaultListAPI,
  getVaultMediaByIdAPI,
  getVaultMediaListAPI,
  updateVaultListAPI,
} from "Api/VaultApi";
import { useVaultStore } from "Hooks/useVaultStore";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

function* getVaultListWorker({ payload }) {
  try {
    const res = yield call(getVaultListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_VAULT_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_VAULT_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getInnerVaultWorker({ payload }) {
  try {
    const res = yield call(getVaultListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_INNER_VAULT,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_INNER_VAULT,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createVaultListworker({ payload }) {
  try {
    const res = yield call(createVaultListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_VAULT_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Media list added successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_VAULT_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong!",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateVaultListWorker({ payload, id }) {
  try {
    const res = yield call(updateVaultListAPI, payload, id);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_VAULT_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Media list updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_VAULT_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong!",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteVaultListWorker({ id, payload }) {
  try {
    const res = yield call(deleteVaultListAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_VAULT_LIST,
        payload: res,
        vaultId: id,
        remove_media: payload?.remove_media,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Media list deleted successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_VAULT_LIST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong!",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getSingleVaultListWorker({ id }) {
  try {
    const res = yield call(getSingleVaultListAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_SINGLE_VAULT_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_SINGLE_VAULT_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* changeVaultListOrderWorker({ payload }) {
  try {
    const res = yield call(changeVaultListOrderAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CHANGE_VAULT_LIST_ORDER,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CHANGE_VAULT_LIST_ORDER,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getVaultMediaListWorker({ payload }) {
  try {
    const res = yield call(getVaultMediaListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_VAULT_MEDIA_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_VAULT_MEDIA_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteVaultMediaWorker({ payload }) {
  try {
    const res = yield call(deleteVaultMediaAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_VAULT_MEDIA,
        payload: res,
        media_ids: payload?.media_ids,
      });
    } else {
      yield put({
        type: ERROR_DELETE_VAULT_MEDIA,
        payload: res,
      });
    }
  } catch (error) {}
}

function* changeMediaCategoryWorker({ id, payload, options }) {
  try {
    const res = yield call(changeMediaCategoryAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CHANGE_MEDIA_CATEGORY,
        payload: res,
        data: { ...payload, id },
      });
      yield put({
        type: START_GET_INNER_VAULT,
        payload: options?.innerList || {},
      });
      yield put({
        type: START_GET_VAULT_LIST,
        payload: options?.list,
      });
    } else {
      yield put({
        type: ERROR_CHANGE_MEDIA_CATEGORY,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getVaultMediaByIdWorker({ payload }) {
  try {
    const res = yield call(getVaultMediaByIdAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_VAULT_MEDIA_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_VAULT_MEDIA_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* VaultWatcher() {
  yield takeEvery(START_GET_VAULT_LIST, getVaultListWorker);
  yield takeEvery(START_GET_INNER_VAULT, getInnerVaultWorker);
  yield takeEvery(START_CREATE_VAULT_LIST, createVaultListworker);
  yield takeEvery(START_UPDATE_VAULT_LIST, updateVaultListWorker);
  yield takeEvery(START_DELETE_VAULT_LIST, deleteVaultListWorker);
  yield takeEvery(START_GET_SINGLE_VAULT_LIST, getSingleVaultListWorker);
  yield takeEvery(START_CHANGE_VAULT_LIST_ORDER, changeVaultListOrderWorker);
  yield takeLatest(START_GET_VAULT_MEDIA_LIST, getVaultMediaListWorker);
  yield takeEvery(START_DELETE_VAULT_MEDIA, deleteVaultMediaWorker);
  yield takeEvery(START_CHANGE_MEDIA_CATEGORY, changeMediaCategoryWorker);
  yield takeEvery(START_GET_VAULT_MEDIA_BY_ID, getVaultMediaByIdWorker);
}
