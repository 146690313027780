import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "service";
import { theme } from "Theme/theme";
import { getThemeMode, protectedRoute } from "utils/generalUtils";

export const useThemeMode = () => {
  const themeMode = useSelector((state) => state?.user?.themeMode);
  const changeTheme = useSelector((state) => state?.config?.changeTheme);
  const isEnteredGlobal = useSelector(
    (state) => state?.global?.isEnteredGlobal
  );

  const token = API.getAccessTokenFromLocalStorage();

  const isLightApply = !protectedRoute?.includes(
    window.location.pathname?.split("/")?.reverse()[0]
  );

  let generateTheme;

  generateTheme = useCallback(() => {
    let finalTheme = theme(
      getThemeMode() || "light",
      isLightApply &&
        (token ||
          (!protectedRoute?.includes(window.location.pathname.split("/")[1]) &&
            window.location.pathname != "/"))
    );
    return finalTheme;
  }, [themeMode, changeTheme, isEnteredGlobal]);

  return generateTheme;
};
