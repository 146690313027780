import { SUCCESS_CREATE_CHAT_MSG } from "Actions/SettingsAction";
import {
  SOCKET_LOGIN_USER,
  SOCKET_LOGOUT_USER,
  SUCCESS_BLOCK_USER,
} from "Actions/UserAction";
import { PURCHASE_MESSAGE_ENDPOINT } from "Api/endpoints";
import {
  START_GET_CHAT_LIST,
  SUCCESS_GET_CHAT_LIST,
  ERROR_GET_CHAT_LIST,
  RESET_CHAT_ACTION,
  START_GET_NEW_MESSAGE_LIST,
  SUCCESS_GET_NEW_MESSAGE_LIST,
  ERROR_GET_NEW_MESSAGE_LIST,
  START_CREATE_NEW_CHAT,
  SUCCESS_CREATE_NEW_CHAT,
  ERROR_CREATE_NEW_CHAT,
  START_GET_CHAT_CONVERSATION,
  SUCCESS_GET_CHAT_CONVERSATION,
  ERROR_GET_CHAT_CONVERSATION,
  START_SEND_MESSAGE,
  SUCCESS_SEND_MESSAGE,
  ERROR_SEND_MESSAGE,
  SOCKET_ADD_NEW_MESSAGE,
  START_READ_MESSAGE,
  SUCCESS_READ_MESSAGE,
  ERROR_READ_MESSAGE,
  START_DELETE_CHAT,
  SUCCESS_DELETE_CHAT,
  ERROR_DELETE_CHAT,
  SOCKET_CHAT_VIDEO_UPLOAD,
  START_DELETE_CHAT_MESSAGES,
  SUCCESS_DELETE_CHAT_MESSAGES,
  ERROR_DELETE_CHAT_MESSAGES,
  START_PURCHASE_MESSAGE,
  SUCCESS_PURCHASE_MESSAGE,
  ERROR_PURCHASE_MESSAGE,
  SOCKET_MESSAGE_PURCHASED,
  SOCKET_USER_TYPING,
  START_GET_SINGLE_MASS_MESSAGE,
  SUCCESS_GET_SINGLE_MASS_MESSAGE,
  ERROR_GET_SINGLE_MASS_MESSAGE,
  START_UPDATE_MASS_MESSAGE,
  SUCCESS_UPDATE_MASS_MESSAGE,
  ERROR_UPDATE_MASS_MESSAGE,
  SUCCESS_GET_CHAT_CONVERSATION_CATCHED,
  SUCCESS_GET_CHAT_LIST_CACHED,
} from "../Actions/ChatAction";
import { SOCKET_IMAGE_MEDIA_UPLOADED } from "Actions/PostAction";

const initialState = {
  chatId: "",
  chatListData: {
    isLoading: false,
    list: [],
    error: "",
    total: 0,
    isLazyLoading: false,
  },
  newMessageListData: {
    isLoading: false,
    list: [],
    error: "",
    total: 0,
    isLazyLoading: false,
  },
  createNewChatData: {
    isLoading: false,
    data: {},
    error: "",
  },
  chatConversationData: {
    isLoading: false,
    data: {},
    error: "",
    isLazy: false,
    revalidateIsLoading : false,
  },
  chatConversationLastMsgIndex: "",
  sendMessageData: {
    isLoading: false,
    data: {},
    error: "",
  },
  readMessageData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteChatData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteChatMessagesData: {
    isLoading: false,
    data: {},
    error: "",
  },
  purchaseMessageData: {
    isLoading: false,
    data: {},
    error: "",
  },
  singleMassMessage: {
    isLoading: false,
    data: {},
    error: "",
    isSuccess: false,
  },
  updateMassMessageData: {
    isLoading: false,
    data: {},
    error: "",
  },
  chatConversationDataCatched: {},
};

export const chatReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_CHAT_ACTION: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_GET_CHAT_LIST: {
      let oldList = state?.chatListData?.list;
      return {
        ...state,
        chatListData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          list: action?.payload?.offset > 0 ? oldList : [],
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_CHAT_LIST_CACHED: {
      let oldList = state?.chatListData?.list;
      if (action?.offset > 0) {
        oldList = [...oldList, ...action?.payload?.chats];
      } else {
        oldList = action?.payload?.chats;
      }
      return {
        ...state,
        chatListData: {
          isLoading: false,
          list: oldList,
          error: "",
          total: action?.payload?.chat_count,
          isLazyLoading: false,
          revalidateIsLoading : true,
        },
        chatListDataCached: {
          list: oldList,
          total: action?.payload?.chat_count,
        },
      };
    }
    
    case SUCCESS_GET_CHAT_LIST: {
      let oldList = state?.chatListData?.list;
      if (action?.offset > 0) {
        oldList = [...oldList, ...action?.payload?.chats];
      } else {
        oldList = action?.payload?.chats;
      }
      return {
        ...state,
        chatListData: {
          isLoading: false,
          list: oldList,
          error: "",
          total: action?.payload?.chat_count,
          isLazyLoading: false,
          revalidateIsLoading : false
        },
        chatListDataCached: {
          list: oldList,
          total: action?.payload?.chat_count,
        },
      };
    }
    case ERROR_GET_CHAT_LIST: {
      return {
        ...state,
        chatListData: {
          isLoading: false,
          list: [],
          error: action.payload,
          total: 0,
          isLazyLoading: false,
        },
      };
    }

    case START_GET_NEW_MESSAGE_LIST: {
      let oldList = state?.newMessageListData?.list;
      return {
        ...state,
        newMessageListData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          list: action?.payload?.offset > 0 ? oldList : [],
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_NEW_MESSAGE_LIST: {
      let oldList = state?.newMessageListData?.list;
      if (action?.offset > 0) {
        oldList = [...oldList, ...action?.payload?.users];
      } else {
        oldList = action?.payload?.users;
      }
      return {
        ...state,
        newMessageListData: {
          isLoading: false,
          list: oldList,
          error: "",
          total: action?.payload?.user_count,
          isLazyLoading: false,
        },
      };
    }
    case ERROR_GET_NEW_MESSAGE_LIST: {
      return {
        ...state,
        newMessageListData: {
          isLoading: false,
          list: [],
          error: action.payload,
          total: 0,
          isLazyLoading: false,
        },
      };
    }

    case START_CREATE_NEW_CHAT: {
      return {
        ...state,
        createNewChatData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_NEW_CHAT: {
      return {
        ...state,
        createNewChatData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CREATE_NEW_CHAT: {
      return {
        ...state,
        createNewChatData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_CHAT_CONVERSATION: {
      let oldChat = { ...state?.chatConversationData?.data };
      return {
        ...state,
        chatConversationData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldChat : {},
          error: "",
          isLazy: action?.payload?.offset > 0 ? true : false,
        },
        chatConversationLastMsgIndex: "",
      };
    }
    case SUCCESS_GET_CHAT_CONVERSATION_CATCHED: {
      let oldChat = { ...state?.chatConversationData?.data };
      if (action?.offset > 0) {
        oldChat.chat_message_count = action?.payload?.chat_message_count;
        oldChat.chat_messages = [
          ...action?.payload?.chat_messages,
          ...oldChat?.chat_messages,
        ];
      } else {
        oldChat = action?.payload;
      }
      let catched = {
        ...state?.chatConversationDataCatched,
      };
      if (action?.offset === 0) {
        catched[action.chat_id] = oldChat;
      }
      return {
        ...state,
        chatConversationData: {
          isLoading: false,
          data: oldChat,
          error: "",
          isLazy: action?.offset > 0 ? true : false,
          revalidateIsLoading : true,
        },
        chatConversationLastMsgIndex: action?.payload?.chat_messages?.length,
        chatConversationDataCatched: catched,
      };
    }
    case SUCCESS_GET_CHAT_CONVERSATION: {
      let oldChat = { ...state?.chatConversationData?.data };
      if (action?.offset > 0) {
        oldChat.chat_message_count = action?.payload?.chat_message_count;
        oldChat.chat_messages = [
          ...action?.payload?.chat_messages,
          ...oldChat?.chat_messages,
        ];
      } else {
        oldChat = action?.payload;
      }
      let catched = {
        ...state?.chatConversationDataCatched,
      };
      if (action?.offset === 0) {
        catched[action.chat_id] = oldChat;
      }
      return {
        ...state,
        chatConversationData: {
          isLoading: false,
          data: oldChat,
          error: "",
          isLazy: action?.offset > 0 ? true : false,
          revalidateIsLoading : false,
        },
        chatConversationLastMsgIndex: action?.payload?.chat_messages?.length,
        chatConversationDataCatched: catched,
      };
    }
    case ERROR_GET_CHAT_CONVERSATION: {
      return {
        ...state,
        chatConversationData: {
          isLoading: false,
          data: {},
          error: action.payload,
          revalidateIsLoading : false,
        },
      };
    }
    case START_SEND_MESSAGE: {
      return {
        ...state,
        sendMessageData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SEND_MESSAGE: {
      let oldChat = { ...state?.chatConversationData?.data };
      if (oldChat.chat_messages?.length > 0) {
        oldChat.chat_message_count = (oldChat?.chat_message_count || 0) + 1;
        oldChat.chat_messages = [
          ...oldChat?.chat_messages,
          { ...action?.payload?.chat_message, is_from_me: true },
        ];
      } else {
        oldChat.chat_message_count = 1;
        oldChat.chat_messages = [
          { ...action?.payload?.chat_message, is_from_me: true },
        ];
      }
      let recentChatReceive;
      let chatList = { ...state?.chatListData };
      chatList.list = chatList?.list?.map((chat, i) => {
        if (chat?.chat_id == state?.chatId) {
          recentChatReceive = i;
          return {
            ...chat,
            message: action?.payload?.chat_message,
          };
        } else {
          return chat;
        }
      });
      let updatedListReceive = chatList?.list[recentChatReceive];
      chatList?.list?.splice(recentChatReceive, 1);
      chatList?.list?.unshift(updatedListReceive);
      return {
        ...state,
        sendMessageData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        chatListData: chatList,
      };
    }
    case SUCCESS_CREATE_CHAT_MSG: {
      let oldChat = { ...state?.chatConversationData?.data };
      let chatList = { ...state?.chatListData };
      let recentChatReceive;
      if (!action?.is_scheduled) {
        if (oldChat.chat_messages?.length > 0) {
          oldChat.chat_message_count = (oldChat?.chat_message_count || 0) + 1;
          oldChat.chat_messages = [
            ...oldChat?.chat_messages,
            { ...action?.payload?.chat_message, is_from_me: true },
          ];
        } else {
          oldChat.chat_message_count = 1;
          oldChat.chat_messages = [
            { ...action?.payload?.chat_message, is_from_me: true },
          ];
        }
      }
      if (!action?.is_scheduled) {
        chatList.list = chatList?.list?.map((chat, i) => {
          if (chat?.chat_id == state?.chatId) {
            recentChatReceive = i;
            return {
              ...chat,
              message: action?.payload?.chat_message,
            };
          } else {
            return chat;
          }
        });
        let updatedListReceive = chatList?.list[recentChatReceive];
        chatList?.list?.splice(recentChatReceive, 1);
        chatList?.list?.unshift(updatedListReceive);
      }
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        chatListData: chatList,
      };
    }
    case ERROR_SEND_MESSAGE: {
      return {
        ...state,
        sendMessageData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_READ_MESSAGE: {
      return {
        ...state,
        readMessageData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_READ_MESSAGE: {
      let chatList = { ...state?.chatListData };

      if (action?.read_type == "fresh") {
        chatList.list = chatList?.list?.map((chat) => {
          if (chat?.chat_id == action?.chat_id) {
            return {
              ...chat,
              unread_count: 0,
            };
          } else {
            return chat;
          }
        });
      } else if (action?.read_type == "open") {
        chatList?.list?.map((chat) => {
          if (chat?.chat_id == action?.chat_id) {
          }
        });
      } else if (action?.read_type == "all") {
        chatList.list = chatList?.list?.map((chat) => ({
          ...chat,
          unread_count: 0,
        }));
      }
      return {
        ...state,
        readMessageData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        chatListData: chatList,
      };
    }
    case ERROR_READ_MESSAGE: {
      return {
        ...state,
        readMessageData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case SUCCESS_BLOCK_USER: {
      let chatId = state?.chatId;
      let chatList = { ...state?.chatListData };
      chatList.list = chatList?.list?.filter((item) => item?.chat_id != chatId);
      chatList.total = chatList?.total - 1;
      return {
        ...state,
        chatListData: chatList,
      };
    }
    case START_DELETE_CHAT: {
      return {
        ...state,
        deleteChatData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_CHAT: {
      let chatList = { ...state?.chatListData };
      let deletedChatUnreadCount = 0;
      chatList?.list?.map((item) => {
        if (action?.chat_ids?.includes(item?.chat_id)) {
          deletedChatUnreadCount =
            deletedChatUnreadCount + (item?.unread_count || 0);
        }
      });
      chatList.list = chatList?.list?.filter(
        (chat) => !action?.chat_ids?.includes(chat?.chat_id)
      );
      chatList.total = chatList?.total - action?.chat_ids?.length;
      return {
        ...state,
        deleteChatData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        chatListData: chatList,
      };
    }
    case ERROR_DELETE_CHAT: {
      return {
        ...state,
        deleteChatData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DELETE_CHAT_MESSAGES: {
      return {
        ...state,
        deleteChatMessagesData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_CHAT_MESSAGES: {
      const { message_ids } = action;
      let chatId = state?.chatId;
      let oldChat = { ...state?.chatConversationData?.data };
      let chatList = { ...state?.chatListData };
      oldChat.chat_message_count = oldChat?.chat_message_count - 1;
      oldChat.chat_messages = oldChat?.chat_messages?.filter(
        (msg) => !message_ids?.includes(msg?.id)
      );
      chatList.list = chatList?.list?.map((recentChat) => {
        if (recentChat?.chat_id == chatId) {
          return {
            ...recentChat,
            message: oldChat?.chat_messages[oldChat?.chat_messages?.length - 1],
          };
        } else {
          return recentChat;
        }
      });
      return {
        ...state,
        deleteChatMessagesData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        chatListData: chatList,
      };
    }
    case ERROR_DELETE_CHAT_MESSAGES: {
      return {
        ...state,
        deleteChatMessagesData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_PURCHASE_MESSAGE: {
      return {
        ...state,
        purchaseMessageData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PURCHASE_MESSAGE: {
      let oldChat = { ...state?.chatConversationData?.data };
      oldChat.chat_messages = oldChat?.chat_messages?.map((item) => {
        if (item?.id == action?.message_id) {
          return {
            ...item,
            ...action?.payload?.chatMessage,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        purchaseMessageData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
      };
    }
    case ERROR_PURCHASE_MESSAGE: {
      return {
        ...state,
        purchaseMessageData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_SINGLE_MASS_MESSAGE: {
      return {
        ...state,
        singleMassMessage: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_SINGLE_MASS_MESSAGE: {
      return {
        ...state,
        singleMassMessage: {
          isLoading: false,
          data: action.payload,
          error: "",
          isSuccess: true,
        },
      };
    }
    case ERROR_GET_SINGLE_MASS_MESSAGE: {
      return {
        ...state,
        singleMassMessage: {
          isLoading: false,
          data: {},
          error: action.payload,
          isSuccess: true,
        },
      };
    }

    case START_UPDATE_MASS_MESSAGE: {
      return {
        ...state,
        updateMassMessageData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_MASS_MESSAGE: {
      return {
        ...state,
        updateMassMessageData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_UPDATE_MASS_MESSAGE: {
      return {
        ...state,
        updateMassMessageData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SOCKET_ADD_NEW_MESSAGE: {
      let chatId = state?.chatId;
      let socketChatId = action?.payload?.chat_id;
      let oldChat = { ...state?.chatConversationData?.data };
      let chatList = { ...state?.chatListData };
      let recentChatReceive = "";
      let userIsAvailable = 0;
      if (
        chatId !== "" &&
        socketChatId == chatId &&
        action?.payload?.message?.from_user_id !==
          root?.user?.userDetail?.data?.data?.id
      ) {
        if (oldChat.chat_messages?.length > 0) {
          oldChat.chat_message_count = (oldChat?.chat_message_count || 0) + 1;
          oldChat.chat_messages = [
            ...oldChat?.chat_messages,
            action?.payload?.message,
          ];
        } else {
          oldChat.chat_message_count = 1;
          oldChat.chat_messages = [action?.payload?.message];
        }
      }
      chatList.list = chatList?.list?.map((recentChat, i) => {
        if (recentChat?.chat_id == socketChatId) {
          recentChatReceive = i;
          userIsAvailable = 1;
          return {
            ...recentChat,
            unread_count:
              chatId == socketChatId
                ? 0
                : recentChat?.unread_count > 0
                ? recentChat?.unread_count + 1
                : 1,
            read_message: chatId == socketChatId ? true : false,
            message: action?.payload?.message,
            unread_msg_count:
              recentChat?.unread_count > 0 ? recentChat?.unread_count + 1 : 1,
          };
        } else {
          return recentChat;
        }
      });
      if (recentChatReceive !== "") {
        let updatedListReceive = chatList?.list[recentChatReceive];
        chatList?.list?.splice(recentChatReceive, 1);
        chatList?.list?.unshift(updatedListReceive);
      }
      if (userIsAvailable == 0) {
        // chatList.list = [
        //   {
        //     ...action?.payload,
        //     is_created_by_me:
        //       action?.payload?.message?.from_user_id ==
        //       root?.user?.userDetail?.data?.data?.id
        //         ? true
        //         : false,
        //   },
        //   ...chatList?.list,
        // ];
        chatList.list = [action?.payload, ...chatList?.list];
        chatList.total = chatList?.total + 1;
      }

      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        chatListData: chatList,
      };
    }

    case SOCKET_CHAT_VIDEO_UPLOAD: {
      let chatId = state?.chatId;
      let oldChat = { ...state?.chatConversationData?.data };
      oldChat.chat_messages = oldChat?.chat_messages?.map((item) => {
        if (item?.id == action?.payload?.chat_message_video?.chat_message_id) {
          return {
            ...item,
            videos: item?.videos?.map((video) => {
              if (video?.id == action?.payload?.chat_message_video?.id) {
                return {
                  ...video,
                  ...action?.payload?.chat_message_video,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
      };
    }

    case SOCKET_IMAGE_MEDIA_UPLOADED: {
      const { category, data } = action.payload;
      let chatId = state?.chatId;
      let oldChat = state?.chatConversationData?.data?.chat_messages;
      if (category == "chat_message_image" && chatId == data?.[0]?.room_id) {
        const { chat_message_id, room_id, id } = data?.[0];
        oldChat = oldChat?.map((item) => {
          if (item?.id == chat_message_id) {
            return {
              ...item,
              images: item?.images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data?.[0],
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: {
            ...state?.chatConversationData?.data,
            chat_messages: oldChat,
          },
        },
      };
    }

    case SOCKET_LOGIN_USER: {
      const { user_id, show_activity_status = true } = action.payload;
      let chatList = { ...state?.chatListData };
      chatList.list = chatList?.list?.map((chat) => {
        if (chat?.is_created_by_me && chat?.sender?.id == user_id) {
          return {
            ...chat,
            sender: {
              ...chat?.sender,
              is_active: 1,
              show_activity_status: show_activity_status,
            },
          };
        } else if (chat?.receiver?.id == user_id) {
          return {
            ...chat,
            receiver: {
              ...chat?.receiver,
              is_active: 1,
              show_activity_status: show_activity_status,
            },
          };
        } else {
          return chat;
        }
      });
      let newChatList = { ...state?.newMessageListData };
      newChatList.list = newChatList?.list?.map((user) => {
        if (user?.id == user_id) {
          return {
            ...user,
            is_active: 1,
            show_activity_status: show_activity_status,
          };
        } else {
          return user;
        }
      });
      let oldChat = { ...state?.chatConversationData?.data };
      oldChat.user = {
        ...oldChat?.user,
        is_active: 1,
        show_activity_status: show_activity_status,
      };
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          chatConversationData: oldChat,
        },
        newMessageListData: newChatList,
        chatListData: chatList,
      };
    }

    case SOCKET_LOGOUT_USER: {
      const { user_id } = action.payload;
      let chatList = { ...state?.chatListData };
      chatList.list = chatList?.list?.map((chat) => {
        if (chat?.is_created_by_me && chat?.sender?.id == user_id) {
          return {
            ...chat,
            sender: {
              ...chat?.sender,
              is_active: 0,
            },
          };
        } else if (chat?.receiver?.id == user_id) {
          return {
            ...chat,
            receiver: {
              ...chat?.receiver,
              is_active: 0,
            },
          };
        } else {
          return chat;
        }
      });
      let newChatList = { ...state?.newMessageListData };
      newChatList.list = newChatList?.list?.map((user) => {
        if (user?.id == user_id) {
          return {
            ...user,
            is_active: 0,
          };
        } else {
          return user;
        }
      });
      let oldChat = { ...state?.chatConversationData?.data };
      oldChat.user = {
        ...oldChat?.user,
        is_active: 0,
      };
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        newMessageListData: newChatList,
        chatListData: chatList,
      };
    }

    case SOCKET_MESSAGE_PURCHASED: {
      let oldChat = { ...state?.chatConversationData?.data };
      oldChat.chat_messages = oldChat?.chat_messages?.map((item) => {
        if (item?.id == action?.payload?.chat_message_id) {
          return {
            ...item,
            is_post_purchased: true,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
      };
    }

    case SOCKET_USER_TYPING: {
      let oldChat = { ...state?.chatConversationData?.data };
      let chatList = state?.chatListData?.list;
      let { is_typing, chat_id, user_id } = action.payload;

      if (Object.keys(oldChat)?.length > 0 && state?.chatId == chat_id) {
        oldChat.is_typing = is_typing;
      }
      if (chatList?.length > 0) {
        chatList = chatList?.map((item) => {
          if (item?.chat_id == chat_id) {
            return {
              ...item,
              is_typing: is_typing,
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        chatConversationData: {
          ...state?.chatConversationData,
          data: oldChat,
        },
        chatListData: {
          ...state?.chatListData,
          list: chatList,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
