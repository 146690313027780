/* eslint-disable eqeqeq */
import { alpha, createTheme, responsiveFontSizes } from "@mui/material";
import { protectedRoute } from "utils/generalUtils";
import {
  MuiAppBar,
  MuiAvatar,
  MuiBadge,
  MuiButton,
  MuiChip,
  MuiDialog,
  MuiDivider,
  MuiFormControlLabel,
  MuiIconButton,
  MuiList,
  MuiMasonry,
  MuiStack,
  MuiTable,
  MuiTabs,
  MuiTimeLine,
  MuiToolbar,
  MuiTypography,
} from "./ThemeProps";

// Define Name for the use of color not like the color name

// const colors = {
//   light : {
//     textColor : "#fff",
//     chip : "#ff0000"
//   },
//   dark : {
//     textColor : "#000",
//     chip : "#ff0000"
//   },
// }

// let mode = "light"
// const getColor = (color) => colors[mode][color]

export const theme = (mode, isLightApply) => {
  let themeColor = {
    primary: {
      main: "#EE1668",
    },
    secondary: {
      main: "#8A9294",
    },
    background: {
      paper: mode == "light" && isLightApply ? "#ffffff" : "#132733",
      // paper: getColor("textColor"),
      default: mode == "light" && isLightApply ? "#F8F9FB" : "#131E24",
      light: mode == "light" && isLightApply ? "#F4F5F8" : "#13232D",
      tabChip: mode == "light" && isLightApply ? "#F4F5F8" : "#132733",
      grey: mode == "light" && isLightApply ? "#F4F5F8" : "#42525C",
    },
    black: {
      main: mode == "light" && isLightApply ? "#333333" : "#ffffff",
    },
    white: {
      main: "#ffffff",
    },
    common: {
      black: "#000000",
    },
    green: {
      main: "#35d063",
    },
    red: {
      main: "#FC5D5B",
    },
  };
  let theme = {
    typography: {
      fontFamily: "Prompt, sans-serif",
    },
    components: {
      MuiTypography: {
        ...MuiTypography,
      },
      MuiButton: {
        ...MuiButton,
      },
      MuiContainer: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            padding: ownerState?.disablePadding
              ? "0px !important"
              : "0px 15px !important",
            maxWidth: "1310px !important",
            [theme.breakpoints.down("mlg")]: {
              maxWidth: "1080px !important",
            },
            [theme.breakpoints.down("slg")]: {
              maxWidth: "720px !important",
            },
          }),
        },
      },
      MuiIconButton: {
        ...MuiIconButton,
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            background: theme.palette.background.paper,
            ...(ownerState?.primary && {
              border: `1px solid ${theme.palette.secondary.main}40`,
              borderRadius: "10px !important",
            }),
          }),
        },
      },
      MuiDivider: {
        ...MuiDivider,
      },
      MuiBadge: {
        ...MuiBadge,
      },
      MuiAppBar: {
        ...MuiAppBar,
      },
      MuiToolbar: {
        ...MuiToolbar,
      },
      MuiChip: {
        ...MuiChip,
      },
      ...MuiTable,
      MuiFormControlLabel: {
        ...MuiFormControlLabel,
      },
      MuiStack: {
        ...MuiStack,
      },
      ...MuiDialog,
      ...MuiAvatar,
      ...MuiTimeLine,
      ...MuiList,
      ...MuiMasonry,
      MuiTabs: { ...MuiTabs },
    },
    breakpoints: {
      values: {
        xs: 0,
        ssxs: 300,
        sxs: 350,
        mxs: 400,
        msm: 500,
        sm: 600,
        smd: 768,
        md: 991,
        slg: 1024,
        mlg: 1280,
        lg: 1310,
        xl: 1536,
      },
    },
    getHoverBgColorFromColor: (color = "primary", hoverOpacity = 0.08) =>
      alpha(themeColor?.[color]?.main, hoverOpacity),
  };

  theme.palette = {
    mode: isLightApply ? mode : "dark",
    ...themeColor,
  };

  let complutedTheme = responsiveFontSizes(createTheme(theme));
  window.theme = complutedTheme;
  return complutedTheme;
};
