import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "Components/Common/MuiComponents";
import SplashScreen from "Container/SplashScreen";
import { instance } from "./service";
import { useThemeMode } from "Hooks/useThemeMode";
const Main = lazy(() => import("Main"));

// const verifySystemStatus = async () => {
//   try {
//     let res = await fetch(
//       `${process.env.REACT_APP_BASE_URL}/auth/verify-system-status`
//     );
//     return res.json();
//   } catch (error) {
//     return error;
//   }
// };

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 500) {
      document.location.href = "/login";
      window.localStorage.removeItem("token");
    }
    // MAINTENANCE_PAGE
    // if (window.location.pathname == "/maintenance") {
    //   window.location.href = "/";
    // }
    return response;
  },
  async (error) => {
    if (error.response.status === 401 || error.response.status === 500) {
      document.location.href = "/login";
      window.localStorage.removeItem("token");
    }
    // MAINTENANCE_PAGE
    // if (!error.response.status && window.location.pathname != "/maintenance") {
    //   try {
    //     let systemStatus = await fetch(
    //       `${process.env.REACT_APP_BASE_URL}/auth/verify-system-status`
    //     );
    //     systemStatus = systemStatus?.json();
    //     if (!systemStatus?.status) {
    //       window.location.href = "/maintenance";
    //     }
    //   } catch (error) {
    //     console.log("error", error);
    //     window.location.href = "/maintenance";
    //   }
    // }
    return error;
  }
);

function App(props) {
  const generateTheme = useThemeMode();

  return (
    <ThemeProvider theme={generateTheme()}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<SplashScreen />}>
          <Main />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
