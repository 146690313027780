export const RESET_LIST_STATE = "RESET_LIST_STATE";

export const START_CREATE_LIST = "START_CREATE_LIST";
export const SUCCESS_CREATE_LIST = "SUCCESS_CREATE_LIST";
export const ERROR_CREATE_LIST = "ERROR_CREATE_LIST";

export const START_GET_LIST = "START_GET_LIST";
export const SUCCESS_GET_LIST = "SUCCESS_GET_LIST";
export const ERROR_GET_LIST = "ERROR_GET_LIST";

export const START_UPDATE_LIST = "START_UPDATE_LIST";
export const SUCCESS_UPDATE_LIST = "SUCCESS_UPDATE_LIST";
export const ERROR_UPDATE_LIST = "ERROR_UPDATE_LIST";

export const START_CLEAR_LIST = "START_CLEAR_LIST";
export const SUCCESS_CLEAR_LIST = "SUCCESS_CLEAR_LIST";
export const ERROR_CLEAR_LIST = "ERROR_CLEAR_LIST";

export const START_DELETE_LIST = "START_DELETE_LIST";
export const SUCCESS_DELETE_LIST = "SUCCESS_DELETE_LIST";
export const ERROR_DELETE_LIST = "ERROR_DELETE_LIST";

export const START_GET_SINGLE_LIST = "START_GET_SINGLE_LIST";
export const SUCCESS_GET_SINGLE_LIST = "SUCCESS_GET_SINGLE_LIST";
export const ERROR_GET_SINGLE_LIST = "ERROR_GET_SINGLE_LIST";

export const START_GET_LIST_USERS = "START_GET_LIST_USERS";
export const SUCCESS_GET_LIST_USERS = "SUCCESS_GET_LIST_USERS";
export const ERROR_GET_LIST_USERS = "ERROR_GET_LIST_USERS";

export const START_GET_ADD_USERS_LIST = "START_GET_ADD_USERS_LIST";
export const SUCCESS_GET_ADD_USERS_LIST = "SUCCESS_GET_ADD_USERS_LIST";
export const ERROR_GET_ADD_USERS_LIST = "ERROR_GET_ADD_USERS_LIST";

export const START_ADD_USERS_TO_LIST = "START_ADD_USERS_TO_LIST";
export const SUCCESS_ADD_USERS_TO_LIST = "SUCCESS_ADD_USERS_TO_LIST";
export const ERROR_ADD_USERS_TO_LIST = "ERROR_ADD_USERS_TO_LIST";
